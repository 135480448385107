import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';

import { QuestConfig } from '../quest-config.interface';
import { QuestValueChange } from '../quest-value-change.interface';
import { QuestQuestion } from '../quest-question.interface';
import { QuestValidators } from '../quest-validation/quest-validators';

/**
 * Renders question - within answer options according to type.
 *
 * @param model
 * @event check
 *
 * @note Checkbox type treated differently - not rendering text in question part but answer (checkbox label)
 */

@Component({
    selector: 'vi-quest-question',
    templateUrl: './quest-question.component.html',
    styleUrls: ['./quest-question.component.scss'],
})
export class QuestQuestionComponent implements OnInit, OnChanges {
    @Input() checking?: boolean;

    @Input() model: QuestQuestion;
    @Input() error: boolean;

    @Output() check: EventEmitter<QuestValueChange> = new EventEmitter();
    @Output() textChange: EventEmitter<QuestValueChange> = new EventEmitter();

    @HostBinding('class.required') required: boolean;

    control: UntypedFormControl;
    focused: boolean;
    invalid: boolean;
    current: { value?: any; error?: string | boolean } = {};

    constructor(public config: QuestConfig) {}

    ngOnInit() {
        if (this.model) {
            this.control = new UntypedFormControl(this.model.value, this.validators());
            if (this.model.disabled) {
                this.control.disable();
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // prev/curr question model (and error separated)
        const prev: QuestQuestion = changes.model && changes.model.previousValue;
        const curr: QuestQuestion = changes.model && changes.model.currentValue;
        const error = changes.error && changes.error.currentValue;
        // set required
        this.required = curr && curr.validate && curr.validate.required;
        // deal with changed current value in model - for value and custom errors
        if (curr) {
            // update value if control exists (not for checklist as it breaks internal state of mat-select-list)
            if (this.control && this.model.type !== 'checklist') {
                // only if question is not focused - as user can be changing the field, or
                // reset existing value - can come from conflict, or
                // set empty value - can come from dependent value update
                if (!this.focused || (!curr.value && this.control.value) || (curr.value && !this.control.value)) {
                    this.control.setValue(curr.value);
                }
                // update stored error/value to use on blur
                if (changes.error) {
                    this.current = { error, value: curr.value };
                }
            }
            // set/clear custom error if any/none
            if (curr.error && curr.error !== true) {
                // async as change is called before init
                setTimeout(() => this.control.setErrors({ ...this.control.errors, custom: curr.error }));
            } else if (this.control && this.control.getError('custom')) {
                // reset previous set
                this.control.setErrors({ ...this.control.errors, custom: null });
            }
        }
        // update validators if validate rules changed
        if (this.control && curr && prev && JSON.stringify(curr.validate) !== JSON.stringify(prev.validate)) {
            this.control.setValidators(this.validators());
            this.control.updateValueAndValidity();
            // then update error flags according to new state
            this.model.error = this.control.value ? this.control.invalid : undefined;
            this.invalid = this.model.error;
        } else if (prev && prev.error === true && !curr.value && (!curr.error || this.required)) {
            // keep previously set errors (set on next/submit explicitly or on reset value if required)
            this.invalid = true;
            // deferred restore error in model
            setTimeout(() => (this.model.error = true));
        } else {
            this.invalid = undefined;
        }
        // and set invalid on error (change without model)
        if (!curr && error) {
            if (this.control) {
                this.control.markAsTouched();
            }
        }
        // disable/enable
        if (curr && this.control && curr.disabled !== this.control.disabled) {
            if (curr.disabled) {
                this.control.disable();
            } else {
                this.control.enable();
            }
        }
    }

    changed(answer: QuestValueChange): void {
        // should not happen if disabled
        if (this.model.disabled) {
            return;
        }
        // set by own as will be not emitted to check
        if (!answer.valid && !this.config.behavior.checkInvalid) {
            this.model.error = !answer.valid;
            this.invalid = true;
        }
        if (answer.onlyText) {
            this.textChange.emit({ ...answer, id: this.model.id, question: this.model });
        } else {
            this.check.emit({ ...answer, id: this.model.id, question: this.model });
        }
    }

    setFocused(set: boolean): void {
        this.focused = set;
        if (set) {
            // remember and reset error on focus - to be set next value check (or restored below for same value)
            this.current.value = this.control.value;
            if (this.model.error !== undefined) {
                this.current.error = this.model.error;
            }
            this.model.error = undefined;
        } else {
            // set error on blur if no value but required
            if (!this.control.value && this.model.validate && this.model.validate.required) {
                this.model.error = true;
            } else if (this.current.value === this.control.value) {
                // restore prev error on no change (as will be not evaluated by model)
                this.model.error = this.current.error;
            }
        }
    }

    protected validators(): ValidatorFn[] {
        const validator = (key: string) => {
            const fn =
                this.model.validate &&
                (this.model.validate[key] || this.model.validate[key] === 0) &&
                QuestValidators[key];
            return fn && fn(this.model.validate[key]);
        };

        return Object.keys(this.model.validate || {})
            .map(validator)
            .filter(Boolean);
    }
}
