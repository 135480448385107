import { EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * Helper for throttling events.
 *
 * @example
 * ``ts
 * import { ThrottledEventEmitter } from '../utils/throttled-event-emitter';
 * // ...
 * @Output() change: EventEmitter<any> = new ThrottledEventEmitter(50);
 * // ...
 * this.change.emit();
 *
 */
export class ThrottledEventEmitter<T> extends EventEmitter<T> {
    protected queue: Subject<T> = new Subject<T>();

    /**
     * @param time Time for throttling - default 100ms
     */
    constructor(time: number = 100) {
        super();
        // define throttled queue wrapping around emit within internal subject
        const queue = this.queue.pipe(throttleTime(time));
        queue.subscribe((change: T) => super.emit(change));
    }

    /**
     * Queues value to emit
     */
    emit(value?: T): void {
        this.queue.next(value);
    }
}
