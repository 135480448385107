import { Component } from '@angular/core';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['../installation.component.scss']
})
export class InstallationComponent {

  public text = '\n@Component({\n' +
      '    selector: \'app-root\',\n' +
      '    templateUrl: \'./app.component.html\',\n' +
      '    styleUrls: [\'./app.component.scss\']\n' +
      '})\n' +
      'export class AppComponent implements OnInit {\n' +
      '\n' +
      '    public model = exampleModel;\n' +
      '\n' +
      '    public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);\n' +
      '\n' +
      '    constructor(private translate: TranslateService) {\n' +
      '    }\n' +
      '\n' +
      '    ngOnInit(): void {\n' +
      '        this.translate.setDefaultLang(\'de-DE\');\n' +
      '    }\n' +
      '\n' +
      '    check(changed: QuestValueChange) {\n' +
      '        console.log(\'SomethingChanged\');\n' +
      '    }\n' +
      '\n' +
      '    page(changed: QuestPartChange) {\n' +
      '        // TODO: do something here\n' +
      '        console.log(\'next/back was pressed!\');\n' +
      '    }\n' +
      '\n' +
      '    submit(model: any) {\n' +
      '        // TODO: decide what to do here\n' +
      '        console.log(\'SUBMIT!\');\n' +
      '    }\n' +
      '}';

  public template = '\n<vi-quest\n' +
      '   [model]="quest"\n' +
      '   (check)="check($event)"\n' +
      '   (partChange)="page($event)"\n' +
      '   (submit)="submit($event)">\n' +
      '</vi-quest>';

  constructor() { }

}
