import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { QuestModule } from '../../../quest/src/lib/quest.module';
import { QuestConfig } from '../../../quest/src/lib/quest-config.interface';
import { environment } from '../environments/environment';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardComponent } from './questions/card/card.component';
import { AppRoutingModule } from './app-routing.module';
import { InstallationComponent } from './installation/general/installation.component';
import { DisplaytypesComponent } from './displaytypes/displaytypes.component';
import { ConfigitComponent } from './installation/configit/configit.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TextComponent } from './questions/text/text.component';
import { CheckboxComponent } from './questions/checkbox/checkbox.component';
import { ChecklistComponent } from './questions/checklist/checklist.component';
import { DatepickerComponent } from './questions/datepicker/datepicker.component';
import { EmptyComponent } from './questions/empty/empty.component';
import { RadiobuttonComponent } from './questions/radiobutton/radiobutton.component';
import { SuggestionComponent } from './questions/suggestion/suggestion.component';
import { FilehandlerComponent } from './questions/filehandler/filehandler.component';
import { FloatComponent } from './questions/float/float.component';
import { IntegerComponent } from './questions/integer/integer.component';
import { HintComponent } from './questions/hint/hint.component';
import { ConfigitQuestAdapterService } from './configit-quest-adapter/configit-quest-adapter.service';
import { ConfigitApiService } from './configit-quest-adapter/configit-api.service';
import { ConflictingValueDialogComponent } from './configit-quest-adapter/conflicting-value-dialog/conflicting-value-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'; // Added here
import { StaticComponent } from './installation/static/static.component';
import { DropdownComponent } from './questions/dropdown/dropdown.component';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.translations, '/download?file_format=i18next');
}

const materialModules = [
    MatTabsModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatFormFieldModule
];

const questConfig: QuestConfig = {
    context: environment.quest.context,
    behavior: {
        allowAnswerReset: true,
        answerResetLabel: '-',
        disableOnCheck: true,
        nextOnWizard: true,
        wizardFooter: true,
        restrictPartChange: false,
        enterButton: true,
        floatInputIncrement: 0.1,
    },
};

@NgModule({
    declarations: [
        AppComponent,
        CardComponent,
        InstallationComponent,
        StaticComponent,
        DisplaytypesComponent,
        ConfigitComponent,
        TextComponent,
        CheckboxComponent,
        ChecklistComponent,
        DatepickerComponent,
        EmptyComponent,
        RadiobuttonComponent,
        SuggestionComponent,
        FilehandlerComponent,
        FloatComponent,
        IntegerComponent,
        HintComponent,
        ConflictingValueDialogComponent,
        DropdownComponent
    ],
    imports: [
        BrowserModule,
        FlexModule,
        QuestModule.forRoot(questConfig),
        ...materialModules,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        MatSidenavModule,
        MatToolbarModule,
        AppRoutingModule,
        MatExpansionModule,
        MatSnackBarModule,
        NgxSkeletonLoaderModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        ConfigitQuestAdapterService, ConfigitApiService],
    bootstrap: [AppComponent],
    exports: [AppComponent]
})
export class AppModule {
}
