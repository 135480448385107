import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfigurationService } from '../services/configuration.service';
import {
    ConfigitAssignmentResp,
    ConfigitConfiguration,
    ConfigitConfigurationResp,
    ConfigitEnvSettings,
    ConfigitTemplate,
    ConfigitTemplateResp,
} from './configit.model';

@Injectable()
export class ConfigitApiService {
    protected baseUrl = environment.configit.baseUrl;

    constructor(protected http: HttpClient, private configuration: ConfigurationService) {}

    /**
     * Request wrapper
     */
    request(method: string, url: string, data?: any): Observable<any> {
        const body = data && Object.assign({}, data);
        const path = this.baseUrl + url;

        return this.http.request(method, path, { body, withCredentials: true });
    }

    config(): ConfigitEnvSettings {
        return environment.configit;
    }

    /**
     * Gets template
     *
     * @param data Additional params replacing defaults form environment.configit
     * @returns Template structure (first)
     */
    getTemplate(data: any = {}): Observable<ConfigitTemplate> {
        const params = this.templateParams(data);

        return this.request('post', '/getMaterialTemplateData', params).pipe(
            map((resp: ConfigitTemplateResp) => resp.templates[0])
        );
    }

    /**
     * Gets configuration
     *
     * @param data Additional params replacing defaults from environment.configit
     * @param assignments Initial assignments
     * @returns Configuration structure (extracted from root)
     */
    getConfiguration(data: any = {}, assignments?: any): Observable<ConfigitConfiguration> {
        const params = this.configurationParams(data, assignments);
        return this.request('post', '/getFromExistingConfiguration', params).pipe(
            map((resp: ConfigitConfigurationResp) => ({
                configuration: resp.root.configuration,
                bomItems: resp.root.bomItems,
            }))
        );
    }

    /**
     * Submits update value
     *
     * @param name Material name
     * @param variable Variable name
     * @param value Variable value
     * @param existing Existing assignments
     * @param data Additional params replacing defaults form environment.configit
     * @returns Configuration data
     */
    setAssignment(
        name: string,
        variable: string,
        value: any,
        existing?: any,
        data?: any
    ): Observable<ConfigitConfiguration> {
        const params = this.updateParams(name, variable, value, existing, data);
        return this.request('post', '/updateValues', params).pipe(
            map((resp: ConfigitAssignmentResp) => this.checkErrors(variable, value, resp)),
            map((resp: ConfigitAssignmentResp) => resp.bomDeltaConfigurationData.configurationData)
        );
    }

    /**
     * Submits configuration
     *
     * @param material Material name
     * @param assignments Existing assignments
     * @param order Order identifier
     * @param captcha Captcha response
     * @param recommend Recommend flag
     * @returns Configuration structure (extracted from root)
     */
    submitConfiguration(material: string, assignments?: any, order?: string, captcha?: string, recommend?: boolean) {
        // TODO handle submit action
        console.log(assignments);
    }

    protected checkErrors(variable: string, value: any, resp: ConfigitAssignmentResp): ConfigitAssignmentResp {
        // as error detected, move generic/unknown error to configuration structure
        if (resp.assignmentError) {
            resp.bomDeltaConfigurationData = {
                configurationData: {
                    uiGroupStates: [],
                    // put invalid value to the state as can be consumed by transformer to keep in UI
                    variableStates: [
                        {
                            fullyQualifiedName: variable,
                            invalidMessage: 'error.invalid',
                            invalidValue: value,
                        },
                    ],
                    // but remove from assignments if exists (f.e. previous valid)
                    assignmentsToRemove: [
                        {
                            variableName: variable,
                        },
                    ],
                },
            };
        }

        return resp;
    }

    /**
     * Constructs template get params - combining given params and defaults from config
     *
     */
    protected templateParams(params: ConfigitEnvSettings = {}): any {
        const config = this.config();

        return {
            name: params.material || config.material,
            languages: [this.configuration.configLanguage],
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: this.configuration.salesAreaName,
            plant: this.configuration.plant
        };
    }

    /**
     * Constructs configuration get params - combining given params and defaults from config
     *
     */
    protected configurationParams(params: ConfigitEnvSettings = {}, assignments: any[] = []): any {
        const config = this.config();

        return {
            name: params.material || config.material,
            languages: [this.configuration.configLanguage],
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: this.configuration.salesAreaName,
            plant: this.configuration.plant,
            rootConfiguration: {
                existingAssignments: assignments,
                materialName: params.material || config.material,
            },
            environment: {
                rootEnvironment: {
                    salesArea: {
                        salesOrganization: this.configuration.plant,
                        distributionChannel: '01'
                    },
                    preselect: params.preselect,
                    submit: params.submit,
                    order: params.order,
                    captcha: params.captcha,
                    recommend: params.recommend && 'true',
                },
            },
        };
    }

    getUpdateTypeAndValue(
        variable: string,
        value: string,
        assignments: any[]
    ): { updateValue: string; updateType: number } {
        let updateType = 1; // 1 = add, 0 = remove
        let updateValue = value;
        const variableAssignments = assignments.filter((a) => a.variableName === variable);
        if (Array.isArray(value)) {
            // multi value question (e.g. checklist),
            // check if the item was added or removed by comparing with current assignments
            const removed = variableAssignments.find((item) => !value.includes(item.valueName));
            if (removed) {
                updateType = 0;
                updateValue = removed.valueName;
            } else {
                // value array has all selected item ids, only send the latest value,
                // that is, the value that does not occur in any of the current assignments
                updateValue = value.find((id) => !variableAssignments.some((item) => item.valueName === id));
            }
        } else {
            // normal single value question
            if (value === undefined || value === null || value === '') {
                updateType = 0;
                updateValue = variableAssignments.length && variableAssignments[0].valueName;
            }
        }
        return { updateValue, updateType };
    }

    protected updateParams(
        name: string,
        variable: string,
        value: string,
        assignments: any[] = [],
        params: ConfigitEnvSettings = {}
    ) {
        const config = this.config();

        const { updateValue, updateType } = this.getUpdateTypeAndValue(variable, value, assignments);

        return {
            name,
            languages: [this.configuration.configLanguage],
            salesAreaId: params.salesAreaId || config.salesAreaId,
            salesAreaName: this.configuration.salesAreaName,
            plant: this.configuration.plant,
            assignment: {
                existingAssignments: assignments,
                itemId: '',
                newAssignment: {
                    action: 'updateValues',
                    assignment: {
                        variableName: variable,
                        updatedValues: [
                            {
                                value: updateValue,
                                updateType,
                            },
                        ],
                        isDefault: false,
                    },
                },
            },
            refreshBom: true,
            environment: {
                rootEnvironment: {
                    salesArea: {
                        salesOrganization: this.configuration.plant,
                        distributionChannel: '01'
                    },
                    order: params.order,
                },
            },
        };
    }
}
