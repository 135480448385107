<div class="root">
    <div class="content">
        <div class="container">
            <div *ngIf="problem">{{ problem }}</div>
            <mat-card appearance="outlined" *ngIf="!problem">
                <mat-card-content>
                    <div fxLayout="column">
                        <div class="skeleton" *ngIf="loading">
                            <div class="row">
                                <div class="col-12">
                                    <ngx-skeleton-loader [theme]="skeleton.label"></ngx-skeleton-loader>
                                </div>
                                <div class="col-12">
                                    <ngx-skeleton-loader [theme]="skeleton.input"></ngx-skeleton-loader>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ngx-skeleton-loader [theme]="skeleton.label"></ngx-skeleton-loader>
                                </div>
                                <div class="col-12">
                                    <ngx-skeleton-loader [theme]="skeleton.input"></ngx-skeleton-loader>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ngx-skeleton-loader [theme]="skeleton.label"></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]="loading">
                        <vi-quest
                                [model]="quest"
                                (check)="check($event)"
                                (partChange)="page($event)"
                                (submit)="submit($event)"
                        ></vi-quest>

                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
