<div class="container">
    <h1>Questionaire library</h1>
    <h3>Usage with a static model</h3>
    <p>Follow these steps to setup your project to use a static model without the usage of configit
        and display the full questionaire in your app:
    </p>

    <pre class="codeBlock">
        <code>
{{" export interface Quest {\n" +
        "       id?: string;\n" +
        "       // Currently supported: single | stepped | wizard | tabbed\n" +
        "       display: QuestDisplayType;\n" +
        "       // Object which holds the different parts of the questionaire and their included questions\n" +
        "       parts: QuestPart[];\n" +
        "       original: any;\n" +
        "       custom?: any;\n" +
        "\n" +
        "       // Captcha response - set on submit if behavior.useCaptcha is set\n" +
        "       captcha?: string;\n" +
        " }"}}
        </code>
    </pre>

    <p>
        Setup enviroments.ts:
    </p>
    <pre class="codeBlock">
    <code>
{{"import { QuestDisplayType } from '@vi/quest';\n" +
    "\n" +
    "export const environment = {\n" +
    "       production: false,\n" +
    "       // Example configuration for usage without Configit\n" +
    "       // Translation is always required\n" +
    "       translations:\n" +
    "           'https://api-develop.viessmann.com/translations/v2/phraseapp-proxy/projects/example-project-id/locales/',\n" +
    "       defaultLanguage: 'de-DE',\n" +
    "       quest: {\n" +
    "           context: 'quest',\n" +
    "           defaultModel: 'ExampleModel',\n" +
    "           // Currently supported: single | stepped | wizard | tabbed\n" +
    "           configurationDisplayType: QuestDisplayType.single\n" +
    "       },\n" +
    "};"}}
        </code>
    </pre>

    <h3>Example usage</h3>
    <p>
        Import QuestModule in your angular module:
    </p>
    <pre class="codeBlock">
        <code>
        {{
            "\n" +
            "import { ConfigitQuestAdapterModule } from './configit-quest-adapter.module';\n" +
            "import { QuestModule } from '@vi/quest';\n" +
            "import { QuestConfig } from '@vi/quest';\n" +
            "import { environment } from '../environments/environment';\n" +
            "import { TranslateLoader, TranslateModule } from '@ngx-translate/core';\n" +
            "import { HttpClient, HttpClientModule } from '@angular/common/http';\n" +
            "import { TranslateHttpLoader } from '@ngx-translate/http-loader';\n" +
            "import { NoopAnimationsModule } from '@angular/platform-browser/animations';\n" +
            "\n" +
            "\n" +
            "\n" +
            "export function createTranslateLoader(http: HttpClient) {\n" +
            "    return new TranslateHttpLoader(http, environment.translations, '/download?file_format=i18next');\n" +
            "}\n" +
            "\n" +
            "// quest config\n" +
            "const questConfig: QuestConfig = {\n" +
            "    context: environment.quest.context,\n" +
            "    behavior: {\n" +
            "        allowAnswerReset: true,\n" +
            "        answerResetLabel: '-',\n" +
            "    },\n" +
            "};\n" +
            "\n" +
            "@NgModule({\n" +
            "    declarations: [\n" +
            "        AppComponent,\n" +
            "    ],\n" +
            "    imports: [\n" +
            "        ConfigitQuestAdapterModule,\n" +
            "        QuestModule.forRoot(questConfig),\n" +
            "        NoopAnimationsModule,\n" +
            "        HttpClientModule,\n" +
            "        TranslateModule.forRoot({\n" +
            "            loader: {\n" +
            "                provide: TranslateLoader,\n" +
            "                useFactory: createTranslateLoader,\n" +
            "                deps: [HttpClient]\n" +
            "            }\n" +
            "        }),\n" +
            "    ],\n" +
            "    providers: [],\n" +
            "    bootstrap: [AppComponent],\n" +
            "    exports: [AppComponent]\n" +
            "})\n" +
            "export class AppModule {\n" +
            "}"
            }}
        </code>
    </pre>

    <p>Use the quest component in one of your components</p>
    <p>Example component.ts</p>
    <pre class="codeBlock">
    <code [innerText]="text">
    </code>
    </pre>

    <p>Example component.html</p>
    <pre class="codeBlock">
    <code [innerText]="template">
    </code>
    </pre>

    <p>Static example model</p>
    <pre class="codeBlock">
    <code [innerText]="'\n' + (staticExampleModel | json)">
    </code>
    </pre>
</div>
