/**
 * Helper for browser type recognition
 *
 * @example
 * ``ts
 * import { BrowserUtils as browser } from '../utils/browser.utils';
 * // ...
 * browser.ie();
 */
export class BrowserUtils {
    /**
     * checks, if current browser is IE
     */
    static ie(): boolean {
        const isIe = /msie\s|trident\//i.test(window.navigator.userAgent);
        return isIe;
    }
}
