import { Directive, OnInit, Input, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { QuestConfig } from '../quest-config.interface';
import { Prefixable } from '../prefixable.interface';

/**
 * Directive injects error message into container basis on form control state
 *
 * @param viQuestValidationError Form control
 *
 * @example
 * <mat-form-field>
 *   <input matInput [formControl]="control">
 *   <mat-error [viQuestValidationError]="control"></mat-error>
 * </mat-form-field>
 */
@Directive({
    selector: '[viQuestValidationError]',
})
export class QuestValidationErrorDirective extends Prefixable implements OnInit {
    @Input('viQuestValidationError') control: UntypedFormControl; // eslint-disable-line  @angular-eslint/no-input-rename

    @HostBinding('innerHTML') html: string;

    constructor(private config: QuestConfig, private translate: TranslateService) {
        super(config && config.context);
    }

    ngOnInit() {
        this.set(this.control.status);
        this.control.statusChanges.subscribe((status) => this.set(status));
    }

    protected set(status: string): void {
        if (status === 'INVALID') {
            const error = Object.keys(this.control.errors).shift();
            // direct on custom, translated otherwise
            if (error === 'custom') {
                this.html = this.control.errors.custom;
            } else {
                this.html = this.translate.instant(this.prefixed('error.' + error), this.control.errors[error]);
            }
        } else {
            this.html = '';
        }
    }
}
