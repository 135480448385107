<ng-container *ngIf="quest" [ngSwitch]="quest.display">
    <vi-quest-parts-single
        *ngSwitchCase="'single'"
        [model]="quest.parts"
        (check)="changed($event)"
        (partChange)="changePart($event)"
        (leave)="exit()"
        [checking]="checking"
    >
    </vi-quest-parts-single>
    <vi-quest-parts-tabbed
        *ngSwitchCase="'tabbed'"
        [model]="quest.parts"
        [selected]="part"
        (check)="changed($event)"
        (partChange)="changePart($event)"
        (partReset)="resetPart($event)"
        (leave)="exit()"
        [checking]="checking"
        [markErrors]="markErrors"
    >
    </vi-quest-parts-tabbed>
    <vi-quest-parts-wizard
        *ngSwitchCase="'wizard'"
        [model]="quest.parts"
        [selected]="part"
        (check)="changed($event)"
        (partChange)="changePart($event)"
        (leave)="exit()"
        [checking]="checking"
        [progress]="quest.progress"
    >
    </vi-quest-parts-wizard>
    <vi-quest-parts-stepped
        *ngSwitchCase="'stepped'"
        [model]="quest.parts"
        [selected]="part"
        (check)="changed($event)"
        (partChange)="changePart($event)"
        [markErrors]="markErrors"
        (partReset)="resetPart($event)"
        (leave)="exit()"
        [checking]="checking"
    >
    </vi-quest-parts-stepped>
</ng-container>
