import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { QuestPartsComponent } from './quest-parts.component';
import { QuestValueChange } from '../quest-value-change.interface';

@Component({
    selector: 'vi-quest-parts-single',
    templateUrl: './quest-parts-single.component.html',
    styleUrls: ['./quest-parts-single.component.scss'],
})
export class QuestPartsSingleComponent extends QuestPartsComponent implements OnInit {
    @ViewChild('form', { static: true }) form: ElementRef<HTMLFormElement>;

    // overwrite changed to set selected part first
    changed(answer: QuestValueChange, part?: number): void {
        this.selected = part;
        super.changed(answer);
    }
    // overwrite submit to set selected part first
    submit(run?: boolean, index?: number): void {
        if (index !== undefined) {
            this.selected = index;
        }
        super.submit(run);
    }

    protected validateAllQuestions(): boolean {
        return true;
    }
}
