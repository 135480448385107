<div class="container">
    <h1>Questionaire library</h1>
    <h3>Introduction</h3>
    <p>Although this library can be used to display any set of questionnaires, it is mostly used in conjunction with a
        configit model. Follow these steps to setup your project to use a configit model, transform this model into a
        quest model (which means: suitable for use with this quest lib) and display the full questionaire in your app:
    </p>

    <pre class="codeBlock">
        <code>
{{" export interface Quest {\n" +
        "       id?: string;\n" +
        "       // Currently supported: single | stepped | wizard | tabbed \n" +
        "       display: QuestDisplayType;\n" +
        "       // Object which holds the different parts of the questionaire and their included questions\n" +
        "       parts: QuestPart[];\n" +
        "       original: any;\n" +
        "       custom?: any;\n" +
        "\n" +
        "       // Captcha response - set on submit if behavior.useCaptcha is set\n" +
        "       captcha?: string;\n" +
        " }"}}
        </code>
    </pre>

    <h3>Install quest lib and peer dependencies</h3>
    <p>
        This package is available in Viessmann Nexus so if you didn't already configure this, do it now:
    </p>
    <pre class="codeBlock">
    <code>
    npm config set registry 'http://nexus.viessmann.com/repository/npm-public/'
    </code>
    </pre>

    <p>
        Install quest lib:
    </p>
    <pre class="codeBlock">
        <code>
    yarn add @vi/quest
        </code>
    </pre>
    <p>
        Install peer dependencies:
    </p>
    <pre class="codeBlock">
        <code>
    yarn add @angular/material @angular/cdk @angular/flex-layout ngx-uploader angular2-recaptcha
        </code>
    </pre>
    <p>
        Setup enviroments.ts:
    </p>
    <pre class="codeBlock">
    <code>
{{"import { QuestDisplayType } from '@vi/quest';\n" +
            "\n" +
            "export const environment = {\n" +
            "       production: false,\n" +
            "       // Example configuration for usage with Configit\n" +
            "       configit: {\n" +
            "           baseUrl: 'https://api-develop.viessmann.com/backend/example-model/v1/configit',\n" +
            "           imageUrl: 'https://api-develop.viessmann.com/backend/example-model/v1/configit/images',\n" +
            "           languages: ['de'],\n" +
            "           salesAreaName: 'Germany',\n" +
            "       },\n" +
            "       // Translation is always required\n" +
            "       translations:\n" +
            "           'https://api-develop.viessmann.com/translations/v2/phraseapp-proxy/projects/example-project-id/locales/',\n" +
            "       defaultLanguage: 'de-DE',\n" +
            "       quest: {\n" +
            "           context: 'quest',\n" +
            "           defaultModel: 'ExampleModel',\n" +
            "           // Currently supported: single | stepped | wizard | tabbed \n" +
            "           configurationDisplayType: QuestDisplayType.single\n" +
            "       },\n" +
            "};"}}
        </code>
    </pre>
</div>
