import { QuestBehavior } from './quest-behavior.interface';

export class QuestConfig {
    /**
     * Context for translations - will be used as prefix for all identifiers
     */
    context?: string;

    /**
     * Icon names used for:
     * - valid/invalid indicators (default check/cancel)
     * - file question (description default)
     * - enable / disable validation icons (default enabled)
     */
    icons?: {
        valid?: string;
        invalid?: string;
        file?: string;
        enabled?: boolean;
    };

    /**
     * Behavior settings
     */
    behavior?: QuestBehavior;

    /**
     * File handling config
     */
    file?: {
        uploadUrl?: string;
        csrfTokenLocalStorageKey?: string;
    };

    /*
     * reCaptcha configuration
     */
    captcha?: {
        siteKey: string;
        /**
         * theme of reCaptcha  ('light'|'dark'); default 'light'
         */
        theme?: 'light' | 'dark';
        /**
         * type of reCaptcha  ('image'|'audio'); default 'image'
         */
        type?: 'image' | 'audio';
        /**
         * size of reCaptcha  ('normal'|'compact'|'invisible' ); default 'normal'
         */
        size?: 'normal' | 'compact' | 'invisible';

        /**
         * version of reCaptcha to use ('v2' | 'v3'); default 'v2'
         */
        version?: 'v2' | 'v3';
    };
    /**
     * hint settings
     * useDialog: max screen size in px upto which hint is show as matDialog, default 1083
     * hintOnMouseOver: for desktop view (screen size > useDialog screen size) show hint onMouseOver instead of click,
     * default: false
     */
    hint?: {
        useDialog?: number;
        hintOnMouseOver?: boolean;
    };
}
