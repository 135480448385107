import { debounceTime } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { QuestAnswerComponent } from './quest-answer.component';

@Component({
    selector: 'vi-quest-answer-radio',
    templateUrl: './quest-answer-radio.component.html',
    styleUrls: ['./quest-answer-radio.component.scss'],
})
export class QuestAnswerRadioComponent extends QuestAnswerComponent implements OnInit {
    description: string;
    hideDescription = true;

    protected focusing: Subject<boolean> = new Subject();

    ngOnInit() {
        // debounce focus change as for radio set each radio button switch
        this.focusing.pipe(debounceTime(100)).subscribe((set) => super.focused(set));
    }

    select(description?: string) {
        if (this.config.behavior.alwaysShowPrevNext) {
            this.description = description;
            this.hideDescription = true;
        }
    }

    focused(set: boolean): void {
        this.focusing.next(set);
    }
}
