<div class="container">
    <h1>Questionaire library</h1>
    <h3>Prerequisites</h3>
    <ul>
        <li>
            a configit model
        </li>
        <li>
            a backend with the following endpoints your app can use to retrieve the model:
            <ul>
                <li>getMaterialTemplateData</li>
                <li>getFromExistingConfiguration</li>
                <li>updateValues</li>
                <li>configuration/submit</li>
            </ul>
        </li>
    </ul>
    <p>Sample backends</p>
    <ul>
        <li>be-product-finder</li>
        <li>be-foerderprofi</li>
        <li>be-package-packer</li>
        <li>be-service-configurator</li>
    </ul>

    <h3>Install peer dependencies</h3>
    <p>Create an angular module named e.g. ConfigitQuestAdapterModule containing the following services and
        components:</p>
    <ul>
        <li>ConfigitApiService: with methods that call the four endpoints mentioned above</li>
        <li>ConfigitQuestAdapterService: must implement @vi/quest/QuestService</li>
        <li>ConfigitQuestModelTransform: must implement @vi/quest/QuestTransform</li>
        <li>ConflictingValueDialog component: angular dialog that pops up if a conflict is detected in configit</li>
    </ul>

    <h3>Example usage</h3>

    <p>
        Import QuestModule and ConfigitQuestAdapterModule in your angular module:
    </p>
    <pre class="codeBlock">
        <code>
        {{
            "\n" +
            "import { ConfigitQuestAdapterModule } from './configit-quest-adapter.module';\n" +
            "import { QuestModule } from '@vi/quest';\n" +
            "import { QuestConfig } from '@vi/quest';\n" +
            "import { environment } from '../environments/environment';\n" +
            "import { TranslateLoader, TranslateModule } from '@ngx-translate/core';\n" +
            "import { HttpClient, HttpClientModule } from '@angular/common/http';\n" +
            "import { TranslateHttpLoader } from '@ngx-translate/http-loader';\n" +
            "import { NoopAnimationsModule } from '@angular/platform-browser/animations';\n" +
            "\n" +
            "\n" +
            "\n" +
            "export function createTranslateLoader(http: HttpClient) {\n" +
            "    return new TranslateHttpLoader(http, environment.translations, '/download?file_format=i18next');\n" +
            "}\n" +
            "\n" +
            "// quest config\n" +
            "const questConfig: QuestConfig = {\n" +
            "    context: environment.quest.context,\n" +
            "    behavior: {\n" +
            "        allowAnswerReset: true,\n" +
            "        answerResetLabel: '-',\n" +
            "    },\n" +
            "};\n" +
            "\n" +
            "@NgModule({\n" +
            "    declarations: [\n" +
            "        AppComponent,\n" +
            "    ],\n" +
            "    imports: [\n" +
            "        ConfigitQuestAdapterModule,\n" +
            "        QuestModule.forRoot(questConfig),\n" +
            "        NoopAnimationsModule,\n" +
            "        HttpClientModule,\n" +
            "        TranslateModule.forRoot({\n" +
            "            loader: {\n" +
            "                provide: TranslateLoader,\n" +
            "                useFactory: createTranslateLoader,\n" +
            "                deps: [HttpClient]\n" +
            "            }\n" +
            "        }),\n" +
            "    ],\n" +
            "    providers: [\n" +
            "        // Needed for the usage of the QuestLib Datepicker\n" +
            "       {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},\n" +
            "       {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},\n" +
            "       // Configit services for API calls \n" +
            "       ConfigitQuestAdapterService, ConfigitApiService],\n" +
            "    bootstrap: [AppComponent],\n" +
            "    exports: [AppComponent]\n" +
            "})\n" +
            "export class AppModule {\n" +
            "}"
            }}
        </code>
    </pre>
    <p>Use the quest component in one of your components</p>
    <p>Example component.ts</p>
    <pre class="codeBlock">
    <code [innerText]="text">
    </code>
    </pre>
    <p>Example component.html</p>
    <pre class="codeBlock">
    <code [innerText]="template">
    </code>
    </pre>

    <p>You can use one of these existing frontend projects as template for the ConfigitQuestAdapterModule and usage of
        the < vi-quest > component:</p>
    <ul>
        <li>fe-product-finder</li>
        <li>fe-service-configurator</li>
        <li>fe-foerderprofi</li>
        <li>fe-package-packer</li>
    </ul>
</div>
