import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { QuestConfig } from '../quest-config.interface';
import { QuestAnswerOption } from '../quest-answer-option.interface';
import { QuestValueChange } from '../quest-value-change.interface';

interface QuestQuestionInterface {
    required: boolean;
    focused: boolean;
    setFocused(set: boolean): void;
}

@Component({
    template: '', // just "abstract" for specified types
})
export class QuestAnswerComponent implements OnChanges {
    private ignore: boolean;

    @Input() id: string;
    @Input() model: QuestAnswerOption[] | QuestAnswerOption;
    @Input() control: UntypedFormControl;
    @Input() placeholder?: string;
    @Input() disabled?: boolean;

    @Input() question: QuestQuestionInterface;

    @Output() change: EventEmitter<QuestValueChange> = new EventEmitter();

    constructor(public config: QuestConfig) {}

    ngOnChanges(changes: SimpleChanges) {
        // Disables controls during quest lib check event
        if (this.control && this.config.behavior.disableOnCheck && this.disabled) {
            this.control.disable();
        }
    }

    changed(event?: Event, onlyText = false) {
        const { valid, value } = this.control;
        // emit with validity flag
        this.change.emit({ value, valid, onlyText });
        // as can come as bubbling event stop propagate original
        this.stop(event);
    }

    keyup(event?: Event) {
        this.changed(event, true);
    }

    enter(event: KeyboardEvent) {
        const element = <HTMLElement>event.target;
        element.blur();
    }

    stop(event?: Event) {
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
    }

    focused(set: boolean, event?: Event): void {
        // whitin ignoring next (click + blur) for a while
        if (!this.ignore) {
            this.question.setFocused(set);
            this.ignore = true;
            setTimeout(() => (this.ignore = false), 100);
        }
    }

    prefixed(text: string): string {
        return this.config.context ? `${this.config.context}.${text}` : text;
    }

    optionId(idx: number, item: { id: any }) {
        return item.id;
    }

    clearValue(event?: Event) {
        this.control.setValue('');
        const { valid, value } = this.control;
        // emit with validity flag
        this.change.emit({ value, valid });
        if (event) {
            event.preventDefault();
        }
    }
}
