import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CardComponent } from './questions/card/card.component';
import { InstallationComponent } from './installation/general/installation.component';
import { ConfigitComponent } from './installation/configit/configit.component';
import { TextComponent } from './questions/text/text.component';
import { CheckboxComponent } from './questions/checkbox/checkbox.component';
import { ChecklistComponent } from './questions/checklist/checklist.component';
import { DatepickerComponent } from './questions/datepicker/datepicker.component';
import { EmptyComponent } from './questions/empty/empty.component';
import { RadiobuttonComponent } from './questions/radiobutton/radiobutton.component';
import { SuggestionComponent } from './questions/suggestion/suggestion.component';
import { FilehandlerComponent } from './questions/filehandler/filehandler.component';
import { FloatComponent } from './questions/float/float.component';
import { IntegerComponent } from './questions/integer/integer.component';
import { HintComponent } from './questions/hint/hint.component';
import { DisplaytypesComponent } from './displaytypes/displaytypes.component';
import { StaticComponent } from './installation/static/static.component';
import { DropdownComponent } from './questions/dropdown/dropdown.component';


const routes: Routes = [
    {
        path: '',
        component: DisplaytypesComponent,
    },
    {
        path: 'start',
        component: InstallationComponent
    },
    {
        path: 'configit',
        component: ConfigitComponent
    },
    {
        path: 'static-model',
        component: StaticComponent
    },
    {
        path: 'card',
        component: CardComponent
    },
    {
        path: 'text',
        component: TextComponent
    },
    {
        path: 'checkbox',
        component: CheckboxComponent
    },
    {
        path: 'checklist',
        component: ChecklistComponent
    },
    {
        path: 'datepicker',
        component: DatepickerComponent
    },
    {
        path: 'empty',
        component: EmptyComponent
    },
    {
        path: 'radio',
        component: RadiobuttonComponent
    },
    {
        path: 'suggestion',
        component: SuggestionComponent
    },
    {
        path: 'filehandler',
        component: FilehandlerComponent
    },
    {
        path: 'float',
        component: FloatComponent
    },
    {
        path: 'integer',
        component: IntegerComponent
    },
    {
        path: 'hint',
        component: HintComponent
    },
    {
        path: 'dropdown',
        component: DropdownComponent
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
