export enum QuestionType {
    card = 'card',
    text = 'text',
    multitext = 'multitext',
    radio = 'radio',
    dropdown = 'dropdown',
    checklist = 'checklist',
    checkbox = 'checkbox',
    date = 'date',
    integer = 'integer',
    float = 'float',
    file = 'file',
    hint = 'hint',
    suggest = 'suggest',
    empty = 'empty', // empty placeholder question
}
