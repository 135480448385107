import { Component } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

@Component({
    selector: 'vi-quest-answer-text',
    templateUrl: './quest-answer-text.component.html',
    styleUrls: ['./quest-answer-text.component.scss'],
})
export class QuestAnswerTextComponent extends QuestAnswerComponent {}
