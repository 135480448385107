import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestValueChange } from '../../../../../quest/src/lib/quest-value-change.interface';
import { QuestPartChange } from '../../../../../quest/src/lib/quest-part-change.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';
import { QuestionAppearance } from '../../../../../quest/src/lib/question-appearance.enum';

const radioModel: Quest = {
      id: 'TestRadioModel',
      display: QuestDisplayType.single,
      // Not neccessary for the QuestLib
      original: undefined,
      parts: [
        {
          id: 'PartRadio',
          title: 'Radiobutton',
          questions: [
            {
              id: 'TestQuestions01',
              type: QuestionType.radio,
              text: 'To be or not to be?',
              value: 'TestValue01',
              options: [
                {
                  id: 'TestQuestionOption01',
                  text: 'Option01',
                },
                {
                  id: 'TestQuestionOption02',
                  text: '<h1>Option02</h1>',
                },
                {
                  id: 'TestQuestionOption03',
                  text: 'Option03',
                  // Can be used for adding custom css classes
                  custom: 'testClass'
                }
              ],
              error: false,
              appearance: QuestionAppearance.full,
            },
          ]
        }
      ]
    };


@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent {

  public model = radioModel;

  public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);

  constructor() {
  }
  check(changed: QuestValueChange) {
    console.log('SomethingChanged');
  }

  page(changed: QuestPartChange) {
    // TODO: do something here
    console.log('next/back was pressed!');
  }

  submit(model: any) {
    // TODO: decide what to do here
    console.log('SUBMIT!');
  }

  private onError(translationKey: string) {
    // TODO: decide what to do with errors
    console.log('error');
  }


}
