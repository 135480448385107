<ng-container *ngIf="model && (!hideIfEmpty || model.subGroups?.length || model.questions.length)">
    <h3 *ngIf="model.headline">
        {{ model.headline }}
        <vi-quest-hint
            *ngIf="!config?.behavior?.showTextInsteadOfGroupTooltip && model.description"
            [model]="{ text: model.description, important: false }"
        ></vi-quest-hint>
    </h3>
    <p
        *ngIf="config?.behavior?.showTextInsteadOfGroupTooltip && model.description"
        [innerHTML]="model.description | trustHtml"
    ></p>
    <div fxLayout="row wrap">
        <ng-container *ngFor="let question of model.questions; trackBy: id">
            <vi-quest-question
                attr.quest-selector="{{ question.id | lowercase }}"
                [attr.class]="question.appearance"
                fxFlex="100"
                [fxFlex.gt-sm]="question.appearance === 'full' ? 100 : 50"
                [model]="question"
                [error]="question.error"
                (check)="changed($event)"
                [checking]="disabled"
            >
            </vi-quest-question>
            <vi-quest-question
                attr.quest-selector="{{ question.id | lowercase }}"
                *ngIf="question.appearance === 'half-standalone' || question.appearance === 'full'"
                [model]="{ type: 'empty', appearance: question.appearance }"
                [fxHide]="question.appearance === 'full'"
            >
            </vi-quest-question>
        </ng-container>
    </div>
    <div fxLayout="row wrap">
        <ng-container *ngIf="model.subGroups">
            <vi-quest-group
                *ngFor="let group of model.subGroups; trackBy: id"
                fxFlex="100"
                [model]="group"
                (check)="changed($event)"
                [hideIfEmpty]="hideIfEmpty"
                [disabled]="disabled"
            >
            </vi-quest-group>
        </ng-container>
    </div>
</ng-container>
