import { Component, OnInit, ViewChild } from '@angular/core';
import { EventService } from './services/event.service';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { QuestDisplayType } from '../../../quest/src/lib/quest-display-type.enum';
import { Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DisplaytypesComponent } from './displaytypes/displaytypes.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'quest-lib-app';

    public showSettings;

    public displayType;
    public model;
    public lang;


    @ViewChild('drawer') sidenav: MatSidenav;

    constructor(public router: Router,
                private eventService: EventService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {

        const parameters = new URLSearchParams(window.location.search);

        this.lang = parameters.get('lang') || environment.defaultLanguage;
        this.displayType = <QuestDisplayType>parameters.get('displayMode') || environment.quest.configurationDisplayType;
        this.model = parameters.get('model') || environment.quest.defaultModel;

        this.translate.setDefaultLang(this.lang);
    }

    public onActivate(componentRef) {
        this.showSettings = componentRef instanceof DisplaytypesComponent ? true : false;
    }

    emitViewChange(displayType: string) {
        this.eventService.emitParamChange({displayMode: displayType});
    }

    emitLangChange(lang: string) {
        this.eventService.emitParamChange({lang: lang});
    }

    emitModelChange(modelId: string) {
        this.eventService.emitParamChange({model: modelId});
    }
}




