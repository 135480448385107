<mat-card appearance="outlined">
    <div fxLayout="row wrap" fxLayoutAlign="space-between">
        <div fxFlex="10">
            <mat-icon>{{ config.icons.file }}</mat-icon>
        </div>
        <div fxFlex="90">
            <div class="support-image-placeholder" *ngIf="config?.behavior?.showImagePlaceholder; else header">
                <div class="image-placeholder-header">
                    <ng-container *ngTemplateOutlet="header"></ng-container>
                </div>
                <div class="image-placeholder-image">
                    <img *ngIf="placeholderImage" src="{{ placeholderImage }}" />
                </div>
            </div>
            <div fxLayout="row space-around">
                <div fxFlex="100" fxFlex.gt-sm="50" *ngIf="template" class="download">
                    <a class="disabled" *ngIf="!template.url">
                        <mat-icon>publish</mat-icon>
                        <div class="label">{{ prefixed('button.download') | translate: template }}</div>
                    </a>
                    <a [attr.href]="template.url" target="_blank" *ngIf="template.url">
                        <mat-icon>publish</mat-icon>
                        <div class="label">{{ prefixed('button.download') | translate: template }}</div>
                    </a>
                </div>
                <div fxFlex="100" fxFlex.gt-sm="50" *ngIf="upload" class="upload">
                    <a (click)="touched()">
                        <input
                            type="file"
                            ngFileSelect
                            [options]="upload"
                            [uploadInput]="startUpload"
                            [attr.accept]="upload.allowedContentTypes.join()"
                            (change)="changed($event)"
                            (uploadOutput)="onUpload($event)"
                            #input
                        />
                        <mat-icon>publish</mat-icon>
                        <div class="label">{{ prefixed(upload?.button || 'button.upload') | translate }}</div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div
        ngFileDrop
        *ngIf="upload"
        [options]="upload"
        (uploadOutput)="onUpload($event)"
        [uploadInput]="startUpload"
        [ngClass]="{ uploading: uploading, uploaded: control?.value, dragging: dragging }"
    >
        <div class="progress">
            {{ prefixed('file.uploading') | translate: uploading }}
            <mat-icon (click)="reset(true)" right>close</mat-icon>
        </div>
        <div class="file">
            <vi-quest-validation-icon [error]="error"></vi-quest-validation-icon>
            {{ prefixed('file.uploaded') | translate: control?.value }}
            <mat-icon (click)="reset()" right>close</mat-icon>
        </div>
    </div>
    <mat-error
        *ngIf="upload"
        [viQuestValidationError]="control"
        [hidden]="!control.touched"
        [style.visibility]="control.touched ? 'visible' : 'hidden'"
    ></mat-error>
</mat-card>

<ng-template #header>
    <h4>{{ text }}</h4>
    <p *ngIf="description" [innerHTML]="description | trustHtml"></p>
</ng-template>
