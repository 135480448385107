import { Component, Input, Output, EventEmitter } from '@angular/core';

import { QuestConfig } from '../quest-config.interface';
import { QuestQuestionGroup } from '../quest-question-group.interface';
import { QuestValueChange } from '../quest-value-change.interface';

@Component({
    selector: 'vi-quest-group',
    templateUrl: './quest-group.component.html',
    styleUrls: ['./quest-group.component.scss'],
})
export class QuestGroupComponent {
    @Input() disabled?: boolean;

    @Input() model: QuestQuestionGroup;

    @Input() hideIfEmpty = false;

    @Output() check: EventEmitter<QuestValueChange> = new EventEmitter();

    constructor(public config: QuestConfig) {}

    changed(answer: QuestValueChange) {
        this.check.emit(answer);
    }

    // TODO: move to common part (maybe prefixable, renamed to Configurable?)
    id(idx: number, item: { id: string }): string {
        return item.id;
    }
}
