<mat-form-field *ngIf="control" floatLabel="never">
    <input
        type="text"
        matInput
        [formControl]="control"
        [name]="id"
        [placeholder]="placeholder"
        [matAutocomplete]="auto"
        (change)="stop($event)"
        (click)="stop($event)"
        (focus)="stop($event)"
        (blur)="blur($event)"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
        <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            <span [innerHTML]="item"></span>
        </mat-option>
        <mat-option *ngIf="showMoreItem" (onSelectionChange)="showMore()">
            <span>{{ config.behavior.suggestShowMoreLabel | translate }}</span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
