import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestAnswerComponent } from './quest-answer.component';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { QuestAnswerOption } from '../quest-answer-option.interface';

@Component({
    selector: 'vi-quest-answer-suggest',
    templateUrl: './quest-answer-suggest.component.html',
    styleUrls: ['./quest-answer-suggest.component.scss'],
})
export class QuestAnswerSuggestComponent extends QuestAnswerComponent implements OnInit {
    @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;

    filteredItems: Subject<string[]> = new Subject<string[]>();
    private allMatches: string[] = [];

    showMoreItem = false;
    maxEntries: number;

    private items: string[];

    private ignoreBlur = false;

    ngOnInit() {
        const maxEntries = this.config.behavior.suggestMaxEntries;
        this.items = (<QuestAnswerOption[]>this.model).map((option: QuestAnswerOption) => option.text);
        this.maxEntries = maxEntries;
        this.control.valueChanges.pipe(debounceTime(500), startWith('')).subscribe((val) => {
            if (val) {
                this.maxEntries = maxEntries; // reset max entries to initial value
                this.doFilter(val);
            }
        });
    }

    private doFilter(item: string) {
        this.showMoreItem = false;

        if (!item || item.length < this.config.behavior.suggestMinChars) {
            // do not show list before user typed at least MIN_CHARS characters
            this.filteredItems.next([]);
            return;
        }
        // split user input into single words (split by whitespace)
        // e.g. 'Vitocal 300-G 6,2kW' -> ['Vitocal', '300-G', '6,2kW']
        const filterValues = item
            .toLowerCase()
            .split(/[ ]+/)
            .filter((v) => !!v);
        // now search for values that contain all filter values the user types
        const matches = this.items.filter((i: string) => {
            return filterValues.every((filterValue) => i.toLowerCase().includes(filterValue));
        });

        // highlight occurrence of filterValues in each match
        if (matches && filterValues) {
            matches.forEach((_: string, index: number) => {
                filterValues
                    .filter((v) => v?.length > 1)
                    .forEach((occurrence: string) => {
                        // wrap in <strong> tag to "highlight" the word
                        const regEx = this.createRegEx(occurrence, true);
                        const replaceWith = matches[index].match(regEx);
                        // we need to maintain the case during replacements:
                        // when the token occurs in different cases (e.g. token 'tw' appears twice, as 'TW' and 'Tw'),
                        // we have to replace 'TW' with <strong>TW</strong> and 'Tw' with <strong>Tw</strong>
                        (replaceWith || []).forEach((rp) => {
                            const caseSensitiveRegex = this.createRegEx(rp, false);
                            const displayValue = matches[index].replace(caseSensitiveRegex, `<b>${rp}</b>`);
                            matches[index] = displayValue;
                        });
                    });
            });
        }

        this.allMatches = matches;
        this.updateMatches();
    }

    private createRegEx(expression: string, ignoreCase: boolean) {
        const escaped = expression.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        return new RegExp(escaped, ignoreCase ? 'ig' : 'g');
    }

    private updateMatches() {
        this.showMoreItem = this.allMatches && this.allMatches.length > this.maxEntries;

        const limited = this.allMatches.slice(0, this.maxEntries);
        this.filteredItems.next(limited);
    }

    showMore() {
        this.maxEntries += this.config.behavior.suggestMaxEntries;
        this.updateMatches();
    }

    blur(event: Event) {
        setTimeout(() => {
            if (!this.ignoreBlur) {
                const value = this.control.value;
                this.change.emit({ value });
            }
        }, 100);
        this.stop(event);
    }

    optionSelected(event: MatAutocompleteSelectedEvent) {
        if (event.option.value) {
            this.ignoreBlur = true;
            // ignore blur events on input control for 200ms
            setTimeout(() => {
                this.ignoreBlur = false;
            }, 200);
            this.allMatches = [];
            this.updateMatches();
            const value = this.normalize(event.option.value);
            // emit with validity flag
            this.change.emit({ value, valid: true });
            this.control.setValue(value);
        } else {
            // the "more" option was clicked.
            // keep the panel open by reopening it on next tick
            // (panel auto-closes on selection)
            setTimeout(() => {
                this.autoTrigger.openPanel();
            });
        }
    }

    normalize(value: string) {
        return value && value.replace(/<b>/g, '').replace(new RegExp('</b>', 'g'), '');
    }
}
