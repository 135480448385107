import { Component, AfterViewInit, OnDestroy, ElementRef, Input } from '@angular/core';

/**
 * Helper directive adding defined state as class to parent mat-step-header element.
 * To put into `ng-template[matStepperIcon]` element.
 *
 * @example
 * <ng-template matStepperIcon="number">
 *   <vi-quest-parts-stepped-step state="number"></vi-quest-parts-stepped-step>
 *   ...
 * </ng-template>
 */

@Component({
    selector: 'vi-quest-parts-stepped-step',
    template: '',
})
export class QuestPartsSteppedStepComponent implements AfterViewInit, OnDestroy {
    @Input() state: string;

    protected element: HTMLElement;
    protected header: HTMLElement;

    constructor(protected ref: ElementRef) {
        this.element = ref.nativeElement;
    }

    ngAfterViewInit(): void {
        this.header = this.element.parentElement.parentElement;
        this.header.classList.add(this.state);
    }

    ngOnDestroy(): void {
        this.header.classList.remove(this.state);
    }
}
