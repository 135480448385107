import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestValueChange } from '../../../../../quest/src/lib/quest-value-change.interface';
import { QuestPartChange } from '../../../../../quest/src/lib/quest-part-change.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';
import { QuestionAppearance } from '../../../../../quest/src/lib/question-appearance.enum';

const emptyModel: Quest = {
      id: 'TestEmptyModel',
      display: QuestDisplayType.single,
      // Not neccessary for the QuestLib
      original: undefined,
      parts: [
        {
          id: 'PartEmpty',
          title: 'Empty question',
          questions: [
            {
              id: 'TestQuestions01',
              type: QuestionType.empty,
              text: 'To be or not to be?',
              value: undefined,
              error: false,
              appearance: QuestionAppearance.full,
            },
          ]
        }
      ]
    };

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent {

  public model = emptyModel;

  public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);

  constructor() {
  }
  check(changed: QuestValueChange) {
    console.log('SomethingChanged');
  }

  page(changed: QuestPartChange) {
    // TODO: do something here
    console.log('next/back was pressed!');
  }

  submit(model: any) {
    // TODO: decide what to do here
    console.log('SUBMIT!');
  }

  private onError(translationKey: string) {
    // TODO: decide what to do with errors
    console.log('error');
  }

}
