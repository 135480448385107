import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { UploaderOptions, UploadOutput, UploadInput, UploadFile, UploadProgress, humanizeBytes } from 'ngx-uploader';

import { QuestFile } from '../quest-file.interface';
import { QuestAnswerComponent } from './quest-answer.component';
import { QuestFileUploadOptions } from '../quest-file-upload-options.interface';
import { QuestQuestionValidate } from '../quest-question-validate.interface';

/**
 * Specialized component for file type answer
 *
 * @param template File details to display as template
 * @param options Upload options
 *
 * @note As a value object with { name, size, type, url } is set.
 * @note Url for file is taken from:
 *   - url property of response (if application/json returned) or
 *   - directly from response body (if starts with http...)
 *
 * @translate button.startUpload
 * @translate button.download
 * @translate file.uploaded - can use QuestFile props
 * @translate file.uploading - can use UploadProgress.data props
 * @translate error.file - can use QuestFileUploadOptions props
 * @translate error.upload - can use UploadFile props
 */

@Component({
    selector: 'vi-quest-answer-file',
    templateUrl: './quest-answer-file.component.html',
    styleUrls: ['./quest-answer-file.component.scss'],
})
export class QuestAnswerFileComponent extends QuestAnswerComponent implements OnChanges {
    @Input() text: string;
    @Input() description: string;
    @Input() error: boolean;
    @Input() template: QuestFile;
    @Input() options: QuestFileUploadOptions;
    @Input() validate: QuestQuestionValidate;
    @Input() placeholderImage: string;

    @Output() startUpload: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    upload: QuestFileUploadOptions;
    dragging: boolean;
    uploading: any;

    protected files: UploadFile[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue) {
            // merge options with defaults
            this.upload = { ...this.uploaderOptions(), ...changes.options.currentValue };
        }
    }

    touched(): void {
        this.control.markAsTouched();
    }

    changed(event?: Event): void {
        // as can come from input file
        this.stop(event);
    }

    onUpload(event: UploadOutput): void {
        switch (event.type) {
            case 'dragOver':
                return this.fileOver(true);
            case 'dragOut':
                return this.fileOver(false);
            case 'allAddedToQueue':
                return this.start();
            case 'addedToQueue':
                return this.added(event.file);
            case 'uploading':
                return this.progress(event.file.progress);
            case 'done':
                return this.done(event.file);
        }
    }

    reset(cancel?: boolean): void {
        this.control.setValue('');
        // reset value to allow choosing the same by button (for file only that way is available)
        if (this.input) {
            this.input.nativeElement.type = '';
            this.input.nativeElement.type = 'file';
        }
        // emit change (but only if previously validated)
        if (this.error === false) {
            super.changed();
        }
        // cancel if called while uploading
        if (cancel) {
            this.uploading = false;
            this.startUpload.emit({ type: 'cancelAll' });
        }
    }

    protected fileOver(set: any): void {
        this.dragging = set;
    }

    protected added(file: UploadFile): void {
        this.files.push(file);
    }

    protected start(): void {
        const multiple = this.upload && this.upload.multiple;
        // only first for single mode (if dragged more)
        const file: UploadFile = !multiple && this.files.shift();
        const invalid = this.invalid(file);

        if (invalid) {
            // means not added
            this.control.setErrors(invalid);
        } else {
            this.error = undefined;
            // special filename feature.
            // currently only used by foerderprofi:
            // if data object contains an entry with value set to 'filename'
            // then we now replace it with the actual filename,
            // since we only have the filename at this very moment
            if (this.options && this.options.data) {
                Object.keys(this.options.data).forEach((key) => {
                    if (this.options.data[key] === 'filename') {
                        this.options.data[key] = file.name;
                    }
                });
            }
            const headers = this.getHeaders();
            // trigger uploader service through event otherwise
            this.startUpload.emit({
                url: (this.upload && this.upload.url) || this.config.file.uploadUrl,
                type: multiple ? 'uploadAll' : 'uploadFile',
                method: 'POST',
                data: this.options && this.options.data,
                file,
                withCredentials: true,
                headers,
            });
        }

        // set "touched"
        this.touched();
        this.dragging = false;
    }

    getHeaders() {
        if (this.config?.file?.csrfTokenLocalStorageKey) {
            const token = localStorage.getItem(this.config.file.csrfTokenLocalStorageKey);
            if (token) {
                return { Authorization: `CSRF ${token}` };
            }
        }
    }

    protected progress(event: UploadProgress): void {
        this.uploading = event.data;
    }

    protected set(file: UploadFile): void {
        // prepare file data
        const name = file.name;
        const size = humanizeBytes(file.nativeFile.size);
        const type = name.split('.').pop().toUpperCase();
        const url = this.url(file.response);
        const response = file.response;

        // set control value
        this.control.setValue({ name, size, type, url, response });
    }

    protected done(file: UploadFile) {
        if (this.success(file.responseStatus)) {
            this.set(file);
            // call base to emit value
            super.changed();
        } else {
            // means not uploaded
            this.control.setErrors({ upload: file });
        }

        // reset queue - TODO: handle multiple
        this.files.length = 0;
        this.uploading = undefined;
    }

    protected invalid(file: UploadFile): ValidationErrors {
        if (!file) {
            return { file: this.upload };
        }
        if (this.validate && this.validate.max) {
            if (file.size > this.validate.max) {
                this.set(file); // set for display
                this.error = true;
                return {
                    max: { max: humanizeBytes(this.validate.max) },
                };
            }
        }
        return null;
    }

    protected url(response: string | any): string {
        return typeof response === 'string' ? response.match(/^http/) && response : response?.url || '';
    }

    protected success(status: number): boolean {
        return status >= 200 && status < 400;
    }

    protected uploaderOptions(): UploaderOptions {
        return {
            allowedContentTypes: (this.options && this.options.allow) || [],
            concurrency: 1,
        };
    }
}
