import { Component, DoCheck, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

import { QuestPartsComponent } from './quest-parts.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
    selector: 'vi-quest-parts-stepped',
    templateUrl: './quest-parts-stepped.component.html',
    styleUrls: ['./quest-parts-stepped.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class QuestPartsSteppedComponent extends QuestPartsComponent implements OnInit, OnChanges, DoCheck {
    @ViewChild('stepper', { static: true }) stepper: MatStepper;

    // Always keep the interacted state of each step to false as validation is handled by each question itself
    ngDoCheck(): void {
        if (this.stepper?.selected && !this.config.behavior.restrictPartChange) {
            this.stepper.selected.interacted = false;
        }
    }

    stepChanged(event: { selectedIndex: number }) {
        if (this.stepper?.selected) {
            this.stepper.selected.interacted = false;
        }
        this.partChange.emit({ index: event.selectedIndex, source: 'header' });
        this.scrollToTop();
    }

    ngOnChanges(changed: SimpleChanges) {
        super.ngOnChanges(changed);

        setTimeout(() => {
            if (!this.config.behavior.restrictPartChange) {
                this.stepper.selected.interacted = false;
            }
        }, 0);
    }
}
