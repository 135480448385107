<mat-drawer-container class="example-container" autosize>

    <mat-drawer #drawer mode="side">
        <mat-nav-list role="list">
            <div mat-subheader>Installation</div>
            <mat-list-item routerLink="start">Get started</mat-list-item>
            <mat-list-item routerLink="configit">Usage with Configit</mat-list-item>
            <mat-list-item routerLink="static-model">Usage with static model</mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Displaytypes</div>
            <mat-list-item routerLink="">Displaytype examples</mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Question types</div>
            <mat-list-item routerLink="card">Card</mat-list-item>
            <mat-list-item routerLink="checkbox">Checkbox</mat-list-item>
            <mat-list-item routerLink="checklist">Checklist</mat-list-item>
            <mat-list-item routerLink="datepicker">Datepicker</mat-list-item>
            <mat-list-item routerLink="dropdown">Dropdown</mat-list-item>
            <mat-list-item routerLink="empty">Question without options</mat-list-item>
            <mat-list-item routerLink="float">Float input</mat-list-item>
            <mat-list-item routerLink="hint">Hint</mat-list-item>
            <mat-list-item routerLink="integer">Integer input</mat-list-item>
            <mat-list-item routerLink="radio">Radiobutton</mat-list-item>
            <mat-list-item routerLink="suggestion">Suggestion</mat-list-item>
            <mat-list-item routerLink="text">Text</mat-list-item>
        </mat-nav-list>
    </mat-drawer>

    <div fxLayout="row nowrap" style="height: 100px; background-color: white"
         fxLayoutAlign="unset center">

        <button mat-icon-button (click)="drawer.toggle()" style="margin-right: 2em; margin-left: 2em">
            <mat-icon>menu</mat-icon>
        </button>

        <div *ngIf="showSettings" style="margin-top: 1em" fxLayout="row nowrap"
             fxLayoutGap="10px" fxLayoutAlign="unset center">

            <mat-form-field appearance="fill">
                <mat-label>Language</mat-label>
                <input #langInput (keyup.enter)="emitLangChange(langInput.value)" matInput
                       placeholder="Ex. en-US" [value]="lang">
            </mat-form-field>
            <button class="ok-button" mat-stroked-button (click)="emitLangChange(langInput.value)">
                Ok
            </button>

            <mat-form-field appearance="fill">
                <mat-label>Model</mat-label>
                <input #modelInput (keyup.enter)="emitModelChange(modelInput.value)" matInput
                       placeholder="Ex. ProductFinder" [value]="model">
            </mat-form-field>
            <button class="ok-button" mat-stroked-button (click)="emitModelChange(modelInput.value)">
                Ok
            </button>


            <mat-form-field appearance="fill">
                <mat-label>Choose a displaytype</mat-label>
                <mat-select [value]="displayType" (valueChange)="emitViewChange($event)">
                    <mat-option value="single">Single</mat-option>
                    <mat-option value="stepped">Stepped</mat-option>
                    <mat-option value="tabbed">Tabbed</mat-option>
                    <mat-option value="wizard">Wizard</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
</mat-drawer-container>
