import { Component } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

/**
 * @translate card.recommend
 */

@Component({
    selector: 'vi-quest-answer-card',
    templateUrl: './quest-answer-card.component.html',
    styleUrls: ['./quest-answer-card.component.scss'],
})
export class QuestAnswerCardComponent extends QuestAnswerComponent {
    description: string;
    hideDescription = true;

    select(id: string, description?: string, event?: Event) {
        if (this.config.behavior.alwaysShowPrevNext) {
            this.description = description;
        }
        // Prevent default scroll down behavior of space press
        if (event instanceof KeyboardEvent && event.code === 'Space') {
            event.preventDefault();
        }

        if (this.control.value === id) {
            // unselect
            if (this.config.behavior.alwaysShowPrevNext) {
                this.hideDescription = true;
            }
            this.control.setValue(null);
            this.change.emit({ value: null, valid: !this.question.required });
        } else {
            if (this.config.behavior.alwaysShowPrevNext) {
                this.hideDescription = !this.description;
            }
            this.control.setValue(id);
            this.change.emit({ value: id, valid: true });
        }
    }

    closeDescription() {
        this.description = undefined;
        this.hideDescription = true;
    }
}
