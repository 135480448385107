import { Component } from '@angular/core';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';

@Component({
    selector: 'app-static',
    templateUrl: './static.component.html',
    styleUrls: ['../installation.component.scss']
})
export class StaticComponent {

    public text = '\n@Component({\n' +
        '    selector: \'app-root\',\n' +
        '    templateUrl: \'./app.component.html\',\n' +
        '    styleUrls: [\'./app.component.scss\']\n' +
        '})\n' +
        'export class AppComponent implements OnInit {\n' +
        '\n' +
        '    public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(staticExampleModel);\n' +
        '\n' +
        '    constructor(private translate: TranslateService) {\n' +
        '    }\n' +
        '\n' +
        '    ngOnInit(): void {\n' +
        '        this.translate.setDefaultLang(\'de-DE\');\n' +
        '    }\n' +
        '\n' +
        '    check(changed: QuestValueChange) {\n' +
        '        // This event fires after each answer to a question.\n' +
        '        console.log(\'SomethingChanged\');\n' +
        '    }\n' +
        '\n' +
        '    page(changed: QuestPartChange) {\n' +
        '        // This event fires after each part change for- and backwards in tabbed or stepped mode.\n' +
        '        console.log(\'next/back was pressed!\');\n' +
        '    }\n' +
        '\n' +
        '    submit(model: any) {\n' +
        '        // This event fires if all questions are answered and the next/submit button is pressed.\n' +
        '        console.log(\'SUBMIT!\');\n' +
        '    }\n' +
        '}';

    public template = '\n<vi-quest\n' +
        '   [model]="quest"\n' +
        '   (check)="check($event)"\n' +
        '   (partChange)="page($event)"\n' +
        '   (submit)="submit($event)">\n' +
        '</vi-quest>';

  public staticExampleModel: Quest = {
    id: 'TestModel',
    display: QuestDisplayType.single,
    original: {},
    parts: [{
      id: 'TestPart1',
      title: 'First Part',
      headline: 'First Part',
      description: '',
      valid: true,
      groups: [{
        id: 'Static test group 1',
        subGroups: [{
          id: 'Static test subgroup 1',
          questions: [{
            id: 'Test question 1',
            text: 'Test question 1 Text',
            type: QuestionType.card,
            value: '',
            options: [{
              id: 'TestOption 1',
              text: 'TestOption Text 1'
            }],
          },
            {
              id: 'Test question 2',
              text: 'Test question 2 Text',
              type: QuestionType.card,
              value: '',
              options: [{
                id: 'TestOption 2',
                text: 'TestOption Text 2'
              }],
            }],
          subGroups: [{
            id: 'Static test subgroup 2',
            questions: [{
              id: 'Test question 3',
              text: 'Test question 3 Text',
              type: QuestionType.card,
              value: '',
              options: [{
                id: 'TestOption 3',
                text: 'TestOption Text 3'
              }],
            },
              {
                id: 'Test question 4',
                text: 'Test question 4 Text',
                type: QuestionType.card,
                value: '',
                options: [{
                  id: 'TestOption 4',
                  text: 'TestOption Text 4'
                }],
              }],
          }]
        }],
      }]
    },
      {
        id: 'TestPart2',
        title: 'Second Part',
        headline: 'Second Part',
        description: '',
        valid: true,
        groups: [{
          id: 'Static test group 2',
          subGroups: [{
            id: 'Static test subgroup 3',
            questions: [{
              id: 'Test question 4',
              text: 'Test question 4 Text',
              type: QuestionType.card,
              value: '',
              options: [{
                id: 'TestOption 4',
                text: 'TestOption Text 4'
              }],
            },
              {
                id: 'Test question 5',
                text: 'Test question 5 Text',
                type: QuestionType.card,
                value: '',
                options: [{
                  id: 'TestOption 5',
                  text: 'TestOption Text 5'
                }],
              }],
            subGroups: [{
              id: 'Static test subgroup 4',
              questions: [{
                id: 'Test question 6',
                text: 'Test question 6 Text',
                type: QuestionType.card,
                value: '',
                options: [{
                  id: 'TestOption 6',
                  text: 'TestOption Text 6'
                }],
              },
                {
                  id: 'Test question 7',
                  text: 'Test question 7 Text',
                  type: QuestionType.card,
                  value: '',
                  options: [{
                    id: 'TestOption 7',
                    text: 'TestOption Text 7'
                  }],
                }],
            }]
          }],
        }]
      },
    ]
  };

    constructor() {
    }
}
