/**
 * Utils container dealing with config values.
 *
 * TODO: to consider renaming to Configurable if new features come
 */
export class Prefixable {
    private context: string;

    constructor(context?: string) {
        if (context) {
            this.context = context;
        }
    }

    /**
     * Prefixes text within defined context, if any (@see QuestConfig, @see QuestModule.forRoot).
     * Mainly for translations.
     *
     * @param text Text to be prefixed
     * @return Prefixed text
     *
     * @example
     * ```html
     * <div>{{ prefixed('some.value') | translate }}</div>
     */
    prefixed(text: string): string {
        return this.context ? `${this.context}.${text}` : text;
    }
}
