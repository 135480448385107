import { Component, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QuestPartsComponent } from './quest-parts.component';
import { QuestQuestion } from '../quest-question.interface';
import { QuestValueChange } from '../quest-value-change.interface';

@Component({
    selector: 'vi-quest-parts-wizard',
    templateUrl: './quest-parts-wizard.component.html',
    styleUrls: ['./quest-parts-wizard.component.scss'],
})
export class QuestPartsWizardComponent extends QuestPartsComponent implements OnInit, OnChanges {
    upcoming: QuestQuestion;
    current: QuestValueChange;
    answeredQuestion: QuestQuestion;
    last: boolean;

    showNextButton = false;

    questionIndex = 0;

    invalid: boolean;

    ngOnChanges(changed: SimpleChanges): void {
        // only single part allowed
        if (changed.model) {
            this.current = undefined;
            this.answeredQuestion = this.model[0].questions[this.questionIndex - 1];
            if (this.answeredQuestion && this.answeredQuestion.error) {
                this.questionIndex--;
            }
            this.upcoming = this.model[0].questions[this.questionIndex];
            if (this.upcoming) {
                this.current = {
                    id: this.upcoming.id,
                    value: this.upcoming.value,
                    question: this.upcoming,
                };
                // Question is already answered, so show nextButton
                if (this.current.value) {
                    this.showNextButton = true;
                    this.current.valid = true;
                }
            } else {
                this.last = true;
                this.questionIndex = this.questionIndex - 1;
                this.upcoming = this.model[0].questions[this.questionIndex];
            }
        }
    }

    /**
     * Sets selected answer (consuming check event from quest-question)
     *
     * @param question
     */
    answer(question: QuestValueChange): void {
        this.current = question;
        this.showNextButton = true;
        // Else question unselect
        if (question.value) {
            if (this.config.behavior.nextOnWizard) {
                this.next();
            }
        }
    }

    textChanged(question: QuestValueChange): void {
        this.current.valid = question.valid;
        this.current.value = question.value;
    }

    /**
     * Overrides next part behavior - emits selected answer
     */
    next() {
        this.questionIndex = this.questionIndex + 1;
        this.check.emit(this.current);
    }

    /**
     * Overrides prev part behavior - "unanswer" current question
     *
     */
    prev() {
        this.last = false;
        this.showNextButton = true;
        // get last answered "back"
        this.questionIndex = this.questionIndex - 1;
        this.upcoming = this.model[0].questions[this.questionIndex];
        // and prepare "new" current to emit (in case of no change of answer)
        this.current = {
            id: this.upcoming.id,
            value: this.upcoming.value,
            question: this.upcoming,
        };
        if (this.current.value) {
            this.showNextButton = true;
            this.current.valid = true;
        }
    }

    @HostListener('window:keyup.backspace')
    backspaceEvent() {
        if (!this.config.behavior.alwaysShowPrevNext) {
            if (this.questionIndex !== 0 && !this.checking) {
                this.prev();
            }
        }
    }
}
