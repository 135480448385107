import { Component } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

@Component({
    selector: 'vi-quest-answer-checklist',
    templateUrl: './quest-answer-checklist.component.html',
    styleUrls: ['./quest-answer-checklist.component.scss'],
})
export class QuestAnswerChecklistComponent extends QuestAnswerComponent {}
