<div class="container">
    <div fxLayout="row" style="margin: 2rem">
        <div fxFlex="<flex-basic>">
            <!--        <mat-card style="margin: 2rem">-->
            <vi-quest
                    [model]="quest"
                    (check)="check($event)"
                    (partChange)="page($event)"
                    (submit)="submit($event)"
            ></vi-quest>
            <!--        </mat-card>-->
        </div>
    </div>

    <div fxLayout="row" style="margin: 2rem">
        <div fxFlex="<flex-basic>">
            <mat-accordion style="margin: 2rem">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Example Model
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <pre class="codeBlock">
            <code [innerHTML]="'\n' + (model | json)"></code>
        </pre>

                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
