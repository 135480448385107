import { Component, Input } from '@angular/core';

import { QuestConfig } from '../quest-config.interface';

/**
 * Component renders validation icon for validated result
 *
 * @param error Flag - true/false for invalid/valid; undefined for no display
 */
@Component({
    selector: 'vi-quest-validation-icon',
    templateUrl: './quest-validation-icon.component.html',
    styleUrls: ['./quest-validation-icon.component.scss'],
})
export class QuestValidationIconComponent {
    @Input() checking?: boolean;

    @Input() disabled: boolean;

    @Input() error: boolean;

    constructor(public config: QuestConfig) {}
}
