import { Component } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

@Component({
    selector: 'vi-quest-answer-dropdown',
    templateUrl: './quest-answer-dropdown.component.html',
    styleUrls: ['./quest-answer-dropdown.component.scss'],
})
export class QuestAnswerDropdownComponent extends QuestAnswerComponent {}
