import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
    Component,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { QuestConfig } from '../quest-config.interface';
import { QuestHintDialogComponent } from './quest-hint-dialog.component';

@Component({
    selector: 'vi-quest-hint',
    templateUrl: './quest-hint.component.html',
    styleUrls: ['./quest-hint.component.scss'],
})
export class QuestHintComponent implements OnInit, OnChanges, OnDestroy {
    private resize: Subscription;
    private ignore: boolean;
    private fixed: boolean;

    myDisplay = 'none';
    @Input() model: { text: string; important: boolean };
    @Input() focused: boolean;

    @HostBinding('class.shown') shown: boolean;

    @HostListener('mouseenter') enter() {
        this.onMouse(true);
    }
    @HostListener('mouseleave') leave() {
        this.onMouse(false);
    }
    @HostListener('document:click', ['$event']) click(event: Event) {
        this.onClick(event);
    }

    constructor(private config: QuestConfig, private dialog: MatDialog) {}

    ngOnInit() {
        setTimeout(() => {
            // dirty workaround for Chrome issue (info icon overlaps with checkbox label on boolean questions)
            this.myDisplay = 'inline-block';
        });
        this.resize = observableFromEvent(window, 'resize')
            .pipe(debounceTime(25))
            .subscribe(() => this.hint(false));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.model && this.model.important && changes.focused) {
            this.hint(changes.focused.currentValue);
        }
    }

    onMouse(hover: boolean): void {
        if (this.config.hint.hintOnMouseOver && !this.useDialog()) {
            this.shown = hover;
        }
    }

    onClick(event: Event, toggle?: boolean): void {
        // Prevent default scroll down behavior of space press
        if (event instanceof KeyboardEvent && event.code === 'Space') {
            event.preventDefault();
        }

        if (toggle === undefined) {
            // means from document - so always close
            this.hint(false);
        } else if (!this.config.hint.hintOnMouseOver) {
            // toggle visibility otherwise
            this.hint(!this.fixed);
            event.preventDefault();
        }
        event.stopPropagation();
    }

    hint(show: boolean): void {
        if (!this.ignore) {
            if (show && this.useDialog()) {
                // deferred to avoid ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => this.open());
            } else {
                this.shown = show;
                this.fixed = show;
            }
        }
    }

    open(): void {
        const dialog = this.dialog.open(QuestHintDialogComponent, {
            data: { text: this.model.text },
        });

        dialog.afterClosed().subscribe(() => {
            this.hint(false);
            // set ignoring focus as focused again after close dialog
            this.ignore = true;
            setTimeout(() => (this.ignore = false), 1000);
        });
    }

    ngOnDestroy() {
        if (this.resize) {
            this.resize.unsubscribe();
        }
    }

    protected useDialog() {
        return window.innerWidth <= this.config.hint.useDialog;
    }
}
