<div>
    <h4>
        <mat-icon>info</mat-icon>
        <span [innerHTML]="headline | trustHtml"></span>
        <span *ngIf="unit"> ({{ unit }})</span>
        <vi-quest-hint *ngIf="hint" [model]="hint" [focused]="isFocused"></vi-quest-hint>
    </h4>
    <div class="description" *ngIf="description" [innerHTML]="description | trustHtml"></div>
    <div class="text" [innerHTML]="text | trustHtml"></div>
    <div class="error-for-disabled" *ngIf="error && disabled" [innerHTML]="error"></div>
</div>
