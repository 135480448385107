import { Component, Input } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';
import { QuestAnswerOption } from '../quest-answer-option.interface';

@Component({
    selector: 'vi-quest-answer-checkbox',
    templateUrl: './quest-answer-checkbox.component.html',
    styleUrls: ['./quest-answer-checkbox.component.scss'],
})
export class QuestAnswerCheckboxComponent extends QuestAnswerComponent {
    @Input() model: QuestAnswerOption;
    @Input() hint?: { text: string; important: boolean };
}
