/**
 * Set of configit related types. As well on response level as internal configit model handled.
 */

export enum ConfigitForceableDisplay {
    // Will prevent values from being displayed
    Hide = 'Hide',
    // Will put forceable values in the order they are defined in configit model
    Interleaved = 'Interleaved',
    // Will put all forceable values in the end of the list of values
    Last = 'Last',
}

export enum ConfigitAssignmentAuthor {
    User = 0,
    Constraint = 1,
    Procedure = 2,
    Default = 3,
    Environment = 4,
    None = 5,
}

export enum ConfigitValueState {
    // Creates conflict on selection. The value can’t be assigned without removing assignments to other variables.
    Forceable = 0,
    // The value can never be assigned. (usually will not be displayed in the frontend)
    Blocked = 1,
    // The value is assigned by the system
    Assignable = 2,
    // The value is assigned by the user
    UserAssigned = 3,
    // The value was selected by system
    SystemAssigned = 4,
    // The value has been removed
    Removed = 5,
}

export interface ConfigitEnvSettings {
    material?: string;
    languages?: string[];
    salesAreaId?: string;
    salesAreaName?: string;
    soldTo?: string;
    shipTo?: string;
    salesOrganization?: string;
    distributionChannel?: string;
    salesDocumentType?: string;
    preselect?: string;
    submit?: string;
    order?: string;
    captcha?: string;
    recommend?: boolean;
}

export interface ConfigitValue {
    state?: ConfigitValueState;
    assignmentAuthor: ConfigitAssignmentAuthor;
    text?: string;
    name?: string;
    image?: string;
    info?: string;
}

export interface ConfigitItemCustomProperties {
    DisplayAs?: string;
    Info?: string;
    CalculatedValue?: any;
    MinValue?: number;
    MaxValue?: number;
    Unit?: string;
    AppearanceWidth?: string;
    FileSize?: string;
    FileType?: string;
    FileUrl?: string;
    DateMax?: string;
    DateMin?: string;
    WithoutDay?: boolean;
}

export interface ConfigitItem {
    fullyQualifiedName?: string;
    text?: string;
    info?: string;
    show?: boolean;
    image?: string;
    variables?: string[];
    controlType?: string;
    customTypeName?: string;
    customProperties?: ConfigitItemCustomProperties;
    valueStates?: ConfigitValue[];
    isMultiValued?: boolean;
    required?: boolean;
    readOnly?: boolean;
    dataType?: string;
    valid?: boolean;
    invalidMessage?: string;
    invalidValue?: string;
    displayForceablesAs?: ConfigitForceableDisplay;
    values?: ConfigitItemValue[];
}

export interface ConfigitItemValue {
    name: string;
    text: string;
    properties: {
        isRecommended: boolean;
    };
}

export interface ConfigitItemsObj {
    [key: string]: ConfigitItem;
}

export interface ConfigitTemplate {
    name: string;
    uiGroups?: any[];
    variables?: any[];
}

export interface ConfigitTemplateResp {
    templates: ConfigitTemplate[];
}

export interface ConfigitAssignment {
    variableName?: string;
    valueName?: string;
    isDefault?: boolean;
    exclusion?: boolean;
    isUserAssignment?: boolean;
}

export interface ConfigitAssignmentsObj {
    [key: string]: ConfigitAssignment;
}

export interface ConfigitAssignmentResp {
    assignmentError?: {
        variableName: string;
    };
    bomDeltaConfigurationData: {
        configurationData: ConfigitConfiguration;
    };
}

export interface ConfigitConflict {
    conflictingAssignments?: string[];
    forceVariableFullyQualifiedName?: string;
    message?: string;
}

export interface ConfigitConfiguration {
    uiGroupStates?: any[];
    variableStates?: any[];
    newAssignments?: ConfigitAssignment[];
    assignmentsToRemove?: ConfigitAssignment[];
    conflict?: ConfigitConflict;
    [key: string]: any;
}

export interface ConfigitConfigurationResp {
    root: {
        configuration: ConfigitConfiguration;
        bomItems: Array<any>;
    };
}

export interface ConfigitConfigurationAndProducts {
    configuration: ConfigitConfiguration;
    bomItems: Array<any>;
}

export interface ConfigitModelOriginal {
    template: ConfigitTemplate;
    configuration: ConfigitConfiguration;
    bomItems: Array<any>;
    assignments?: ConfigitAssignment[];
}
