import { Component, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Quest, QuestCheckResult, QuestDisplayType, QuestPartChange, QuestValueChange } from '../../../../quest/src/public_api';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfigitQuestAdapterService } from '../configit-quest-adapter/configit-quest-adapter.service';
import { ConfigurationService } from '../services/configuration.service';
import { Router } from '@angular/router';
import { EventService } from '../services/event.service';

@Component({
    selector: 'app-displaytypes',
    templateUrl: './displaytypes.component.html',
    styleUrls: ['./displaytypes.component.scss'],
})
export class DisplaytypesComponent implements OnInit {
    public quest: Subject<Quest> = new Subject<Quest>();
    public problem?: string;

    public displayType;
    public model;
    public lang;

    public loading = true;

    /* skeleton themes */
    public skeleton = {
        label: {
            height: '32px',
            width: '60px',
        },
        card: {
            small: {
                height: '80px',
            },
            big: {
                height: '300px',
            },
        },
        input: {
            height: '40px',
        },
    };

    constructor(
        private quests: ConfigitQuestAdapterService,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
        private configuration: ConfigurationService,
        private router: Router,
        private eventService: EventService
    ) {
    }

    ngOnInit(): void {

        this.eventService.changeEmitted.subscribe(params => {
            if (params.lang) {
                this.changeLang(params.lang);
            }
            if (params.model) {
                this.changeModel(params.model);
            }
            if (params.displayMode) {
                this.changeView(params.displayMode);
            }
        });

        const parameters = new URLSearchParams(window.location.search);

        this.lang = parameters.get('lang') || environment.defaultLanguage;
        this.displayType = <QuestDisplayType>parameters.get('displayMode') || environment.quest.configurationDisplayType;
        this.model = parameters.get('model') || environment.quest.defaultModel;

        this.translate.setDefaultLang(this.lang);
        this.configuration.lang = this.lang;

        this.quests.changeDisplayType(this.displayType);

        // TODO: When name of env variable for language will be known we will add extra parameter for it here
        this.quests
            .get(this.model)
            .subscribe((model: Quest) => this.setModel(model),
                () => {
                    this.onError(`Could not find model ${this.model}`);
                    this.loading = false;
                });

        this.router.navigate([], {
            queryParams: {lang: this.lang, model: this.model, displayMode: this.displayType},
            queryParamsHandling: 'merge'
        });
    }


    changeView(displayType: string) {
        this.loading = true;

        switch (displayType) {
            case 'single':
                this.displayType = QuestDisplayType.single;
                this.quests.changeDisplayType(QuestDisplayType.single);
                break;
            case 'stepped':
                this.displayType = QuestDisplayType.stepped;
                this.quests.changeDisplayType(QuestDisplayType.stepped);
                break;
            case 'tabbed':
                this.displayType = QuestDisplayType.tabbed;
                this.quests.changeDisplayType(QuestDisplayType.tabbed);
                break;
            case 'wizard':
                this.displayType = QuestDisplayType.wizard;
                this.quests.changeDisplayType(QuestDisplayType.wizard);
                break;
        }

        this.router.navigate([], {
            queryParams: {displayMode: displayType},
            queryParamsHandling: 'merge'
        });

        this.quests
            .get(this.model)
            .subscribe((model: Quest) => this.setModel(model));
    }

    changeLang(lang: string) {
        this.lang = lang;
        this.configuration.lang = this.lang;
        this.translate.setDefaultLang(lang);

        this.router.navigate([], {
            queryParams: {lang: lang},
            queryParamsHandling: 'merge'
        });

        this.quests
            .get(this.model)
            .subscribe((model: Quest) => this.setModel(model));
    }

    changeModel(modelId: string) {
        this.loading = true;

        this.model = modelId;
        this.quests
            .get(modelId)
            .subscribe((model: Quest) => {
                    this.setModel(model);

                    this.router.navigate([], {
                        queryParams: {model: modelId},
                        queryParamsHandling: 'merge'
                    });
                },
                () => {
                    this.onError(`Could not find model ${modelId}`);
                    this.loading = false;
                });
    }

    private setModel(model: Quest) {
        this.loading = false;
        this.quest.next(model);
    }

    check(changed: QuestValueChange) {
        this.quests
            .check(changed)
            .pipe(map((result: QuestCheckResult) => result.model))
            .subscribe(
                (model: Quest | undefined) => {
                    if (model) {
                        this.setModel(model);
                    } else {
                        throw new Error('missing model');
                    }
                },
                () => this.onError('COMMON.SNACK_BAR.UPDATE_VALUES.ERROR')
            );
    }

    page(changed: QuestPartChange) {
        // TODO: do something here
        console.log('next/back was pressed!');
    }

    submit(model: any) {
        // TODO: decide what to do here
        console.log('SUBMIT!');
    }

    private onError(errorMsg: string) {
        // TODO: decide what to do with errors
        this.snackbar.open(errorMsg, '', {
            panelClass: ['error-snackbar'],
            duration: 3000,
        });
    }
}
