<div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="space-between" [class.answered]="control.value">
    <mat-card
        appearance="outlined"
        fxFlex
        *ngFor="let option of model"
        (click)="select(option.id, option.description)"
        (keyup.enter)="select(option.id, option.description)"
        (keydown.space)="select(option.id, $event)"
        [tabIndex]="disabled ? -1 : 0"
        [ngClass]="option.custom"
        [class.selected]="control.value === option.id"
        [class.recommended]="option.recommended"
        [attr.data-recommendation-key]="prefixed('card.recommend') | translate"
    >
        <mat-card-content>
            <div fxLayout="row wrap" fxLayoutGap="20px" fxLayoutAlign="space-between">
                <div fxFlex="30" class="img">
                    <img *ngIf="option.image" [src]="option.image" />
                </div>
                <div fxFlex>
                    <h5 [innerHTML]="option.text | trustHtml"></h5>
                    <p [innerHTML]="option.description | trustHtml" *ngIf="!config?.behavior?.alwaysShowPrevNext"></p>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="mat-card-description-wrapper" [hidden]="hideDescription" *ngIf="config?.behavior?.alwaysShowPrevNext">
    <div class="mat-card-description" [innerHTML]="description | trustHtml"></div>
</div>

<div class="mat-form-field-subscript-wrapper">
    <mat-error
        [viQuestValidationError]="control"
        [style.visibility]="control.touched || control.value ? 'visible' : 'hidden'"
        [hidden]="!control.touched"
    ></mat-error>
    <div
        class="error-for-disabled"
        *ngIf="control.hasError('custom') && disabled"
        [innerHTML]="control.getError('custom')"
    ></div>
</div>
