<mat-horizontal-stepper
    [linear]="config.behavior.restrictPartChange"
    [selectedIndex]="selected"
    [@.disabled]="true"
    (selectionChange)="stepChanged($event)"
    #stepper
    [class.disabled]="config.behavior.disableOnCheck && checking"
    *ngIf="model?.length"
>
    <ng-template matStepperIcon="edit" let-index="index">
        <vi-quest-parts-stepped-step state="edit"></vi-quest-parts-stepped-step>
        <!-- edit icon is shown even if step is completed unless state is set to not-editable -->
        <vi-icon *ngIf="model[index].valid === undefined" [icon]="model[index].icon || 'edit'"></vi-icon>
        <vi-quest-validation-icon [error]="model[index].valid ? false : undefined"></vi-quest-validation-icon>
    </ng-template>

    <ng-template matStepperIcon="number" let-index="index">
        <vi-quest-parts-stepped-step state="number"></vi-quest-parts-stepped-step>
        <!-- overwrite icon with part specific icon if available, otherwise show step number -->
        <vi-icon *ngIf="model[index].icon" [icon]="model[index].icon"></vi-icon>
        {{ !model[index].icon ? index + 1 : '' }}
    </ng-template>

    <ng-template matStepperIcon="error" let-index="index">
        <vi-quest-parts-stepped-step state="error"></vi-quest-parts-stepped-step>
        <!-- step is completed and state is set to not-editable -->
        <vi-quest-validation-icon [error]="!model[index].valid"></vi-quest-validation-icon>
    </ng-template>

    <ng-template matStepperIcon="done" let-index="index">
        <vi-quest-parts-stepped-step state="done"></vi-quest-parts-stepped-step>
        <!-- step is completed and state is set to not-editable -->
        <vi-quest-validation-icon [error]="model[index].valid ? false : undefined"></vi-quest-validation-icon>
    </ng-template>

    <mat-step
        *ngFor="let part of model; let i = index; trackBy: id; last as last"
        [completed]="part.valid"
        [editable]="!part.disabled || !config.behavior.restrictPartChange"
        [hasError]="model[i].valid === false"
    >
        <ng-template matStepLabel>{{ part.title }}</ng-template>
        <div fxLayout="row" *ngIf="config?.behavior?.resettableParts?.enabled">
            <button
                mat-raised-button
                color="primary"
                type="button"
                class="reset"
                (click)="reset()"
                [disabled]="checking || !resettable()"
            >
                {{ prefixed('button.reset') | translate }}
            </button>
        </div>
        <h2>{{ part.headline }}</h2>
        <p [innerHTML]="part.description | trustHtml" *ngIf="part.description"></p>
        <form>
            <div fxLayout="row wrap">
                <ng-container *ngIf="part.groups && !part.questions">
                    <vi-quest-group
                        *ngFor="let group of part.groups; trackBy: id"
                        fxFlex="100"
                        [model]="group"
                        (check)="changed($event)"
                        [hideIfEmpty]="config.behavior.hideEmptyGroups"
                        [disabled]="checking"
                    >
                    </vi-quest-group>
                </ng-container>
                <ng-container *ngIf="part.questions && !part.groups">
                    <ng-container *ngFor="let question of part.questions; trackBy: id">
                        <vi-quest-question
                            attr.quest-selector="{{ question.id | lowercase }}"
                            [attr.class]="question.appearance"
                            fxFlex="100"
                            [fxFlex.gt-sm]="question.appearance === 'full' ? 100 : 50"
                            [model]="question"
                            [error]="question.error"
                            (check)="changed($event)"
                            [checking]="checking"
                        >
                        </vi-quest-question>
                        <vi-quest-question
                            attr.quest-selector="{{ question.id | lowercase }}"
                            *ngIf="question.appearance === 'half-standalone' || question.appearance === 'full'"
                            [model]="{ type: 'empty', appearance: question.appearance }"
                            class="empty"
                            [fxHide]="question.appearance === 'full'"
                        >
                        </vi-quest-question>
                    </ng-container>
                </ng-container>
            </div>
            <ng-container *ngIf="part.submit || (last && part.submit !== false); then submitButton"></ng-container>
            <ng-container *ngIf="!last && !part.submit; then nextButton"></ng-container>
            <ng-container
                *ngIf="
                    selected > 0 && (!model[selected - 1].disabled || !config.behavior.restrictPartChange);
                    then prevButton
                "
            ></ng-container>
            <ng-container *ngIf="!part.submit && part.exit; then exitButton"></ng-container>
        </form>

        <ng-template #prevButton>
            <button class="prev" mat-button matStepperPrevious color="default" type="button" [disabled]="checking">
                {{ prefixed('button.prev') | translate }}
            </button>
        </ng-template>
        <ng-template #nextButton>
            <button mat-raised-button color="primary" type="button" (click)="next()" [disabled]="checking" class="next">
                {{ prefixed('button.next') | translate }}
            </button>
        </ng-template>
        <ng-template #submitButton>
            <re-captcha
                *ngIf="config?.behavior?.useCaptcha && (!config?.captcha?.version || config?.captcha?.version === 'v2')"
                [site_key]="config.captcha?.siteKey"
                [language]="lang"
                [size]="config.captcha?.size"
                [theme]="config.captcha?.theme"
                [type]="config.captcha?.type"
                (captchaResponse)="handleCaptcha($event)"
                (captchaExpired)="expired()"
            >
            </re-captcha>
            <button
                mat-raised-button
                color="primary"
                type="button"
                (click)="submit()"
                [disabled]="submitDisabled || checking"
                class="submit"
            >
                <ng-container *ngIf="!part.submit?.key">{{
                    prefixed('button.' + ((part.submit === true && 'submit') || part.submit || 'submit')) | translate
                }}</ng-container>
                <ng-container *ngIf="part.submit?.key">{{
                    prefixed('button.' + part.submit.key) | translate: part.submit.params
                }}</ng-container>
            </button>
        </ng-template>
        <ng-template #exitButton>
            <button class="exit" mat-raised-button color="primary" type="button" (click)="exit()">
                {{ prefixed('button.exit') | translate }}
            </button>
        </ng-template>
    </mat-step>
</mat-horizontal-stepper>
