import { Component, Input } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

import { QuestConfig } from '../quest-config.interface';
import { QuestAnswerComponent } from './quest-answer.component';
import { Moment } from 'moment';
import { DateUtils } from '../utils/DateUtils';

@Component({
    selector: 'vi-quest-answer-date',
    templateUrl: './quest-answer-date.component.html',
    styleUrls: ['./quest-answer-date.component.scss'],
})
export class QuestAnswerDateComponent extends QuestAnswerComponent {
    public maxDate: Moment;
    public minDate: Moment;

    @Input()
    public withoutDay: boolean;

    @Input() set max(value: string) {
        if (value) {
            this.maxDate = DateUtils.parseDateMax(value);
        }
    }

    @Input() set min(value: string) {
        if (value) {
            this.minDate = DateUtils.parseDateMin(value);
        }
    }

    constructor(config: QuestConfig, private date: DateAdapter<any>) {
        super(config);
    }

    updated(event?: MatDatepickerInputEvent<any>) {
        const input = event.targetElement as HTMLInputElement;
        this._updated(input);
    }

    private _updated(input: HTMLInputElement) {
        const raw = input.value;
        const valid = this.control.valid;
        const value = (valid && this.date.toIso8601(this.control.value)) || raw;

        // emit only valid values as date (or empty to reset)
        if (this.control.valid || !raw) {
            this.change.emit({ valid, value });
        }
    }

    monthSelected(value: Moment, datepicker: MatDatepicker<Moment>, input: HTMLInputElement) {
        if (this.withoutDay) {
            datepicker.close();
            const date = value.set('date', 1);
            this.control.setValue(date);
            this._updated(input);
        }
    }
}
