<mat-icon
    *ngIf="error !== undefined && !checking && !(config.behavior.tooltipForDisabled && disabled)"
    [color]="error ? 'error' : 'success'"
>
    {{ error ? config.icons.invalid : config.icons.valid }}
</mat-icon>
<mat-spinner *ngIf="checking && config.behavior.disableOnCheck" mode="indeterminate" [diameter]="25"></mat-spinner>
<mat-icon
    *ngIf="config.behavior.tooltipForDisabled && disabled && !checking"
    class="info-tooltip"
    [matTooltip]="config.behavior.tooltipForDisabled | translate"
    matTooltipPosition="left"
    >info</mat-icon
>
