import {
    Quest,
    QuestAnswerOption,
    QuestDisplayType,
    QuestFile,
    QuestFileUploadOptions,
    QuestionAppearance,
    QuestionType,
    QuestPart,
    QuestQuestion,
    QuestQuestionGroup,
    QuestQuestionValidate,
    QuestTransform,
    QuestValidators,
} from '../../../../quest/src/public_api';
import {
    ConfigitAssignment,
    ConfigitAssignmentAuthor,
    ConfigitAssignmentsObj,
    ConfigitForceableDisplay,
    ConfigitItem,
    ConfigitItemCustomProperties,
    ConfigitItemsObj,
    ConfigitItemValue,
    ConfigitModelOriginal,
    ConfigitValue,
    ConfigitValueState,
} from './configit.model';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { QuestDateOptions } from '../../../../quest/src/lib/quest-date-options.interface';

export class ConfigitQuestModelTransform implements QuestTransform {
    /**
     * Question types mapping - from controlType, customProperties.DisplayAs, dataType and isMultiValued properties
     * @see questionType
     *
     * @note dataType only for TextBox
     */
    protected questionTypes = {
        'ComboBox-Card': QuestionType.card,
        'ComboBox-DropDown': QuestionType.dropdown,
        ComboBox: QuestionType.dropdown,
        'ComboBox-Multi': QuestionType.checklist,
        'ListBox-Multi': QuestionType.checklist,
        'RadioButton-Boolean': QuestionType.checkbox,
        'RadioButton-RadioButton': QuestionType.radio,
        'TextBox-Int': QuestionType.integer,
        'TextBox-Float': QuestionType.float,
        'TextBox-InputField-Float': QuestionType.float,
        'TextBox-Date-String': QuestionType.date,
        'TextBox-File-String': QuestionType.file,
        'TextBox-FileUpload-String': QuestionType.file,
        'ComboBox-Hint': QuestionType.hint,
        'ComboBox-Hint-Multi': QuestionType.hint,
        'RadioButton-Hint': QuestionType.hint,
        'TextArea-Hint': QuestionType.hint,
        'TextBox-Hint': QuestionType.hint,
        'TextBox-Hint-Int': QuestionType.hint,
        'TextBox-Hint-Float': QuestionType.hint,
        'TextBox-Hint-String': QuestionType.hint,
        'ComboBox-TextSuggest': QuestionType.suggest,
    };

    /**
     * Allowed file types for upload
     */
    protected fileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    /**
     * Allowed max file size for upload
     */
    protected fileMaxSize = 10485760; // 10MB

    /**
     * String treated as empty headline (subgroup so far)
     */
    protected anonymousIndicator = 'Anonymous';

    public constructor(
        private translate: TranslateService,
        private imageUrl: string = environment.configit.imageUrl,
        private downloadUrl: string,
        private displayType: QuestDisplayType = QuestDisplayType.single
    ) {}

    changeDisplayType(type: QuestDisplayType) {
        this.displayType = type;
    }

    /**
     * Transforms original configit model into quest model
     *
     */
    transform(model: ConfigitModelOriginal): Quest {
        const id = model.template.name;
        const config = model.configuration;
        const original = model;
        const display = this.transformType();
        // extract by unique property
        const unique = 'fullyQualifiedName';
        const uigroups: ConfigitItemsObj = this.objectize(model.template.uiGroups || [], unique);
        const variables: ConfigitItemsObj = this.objectize(model.template.variables || [], unique);
        const states = this.objectize((config.uiGroupStates || []).concat(config.variableStates), unique);
        const values = this.objectize(model.assignments || [], 'variableName');
        // helper methods; subgroup assumes name with '.' (current configit implementation - connection by name only)
        const visible: (part: ConfigitItem) => boolean = (part) => !!part.show;
        const subgroup: (part: ConfigitItem) => boolean = (part) => /\./.test(<string>part.fullyQualifiedName);
        const parent: (part: ConfigitItem) => string = (part) =>
            (<string>part.fullyQualifiedName).replace(/\.[^.]+$/, '');
        // fix variables (insert) for subgroups parents
        Object.keys(uigroups).forEach((name) => {
            const group = uigroups[name];
            if (subgroup(group)) {
                const parentName = parent(group);
                const parentVariables = uigroups[parentName].variables || [];
                if (!parentVariables.includes(name)) {
                    parentVariables.push(name);
                }
            }
        });

        // Filter SettingsPart if available => Settings are not displayed so there is no need to transform the settings part
        const tempUiGroupStates = (config.uiGroupStates || []).filter(
            (uiGroupState) => uiGroupState[unique] !== environment.app.settingsPartId
        );
        const shown = (tempUiGroupStates || []).filter(visible).filter((part) => !subgroup(part));

        // then only visible groups as parts; and not subgroups
        const parts = shown.map((part: any) => this.transformPart(part, uigroups, variables, states, values));
        // then set disabled flag - enabled first or next after valid (if not explicitely disabled by control)
        parts.forEach((part, idx) => {
            if (part.disabled === true) {
                part.valid = false;
            } else {
                part.disabled = (idx && !parts[idx - 1].valid) || false;
            }
        });

        const progress = Number(
            original.assignments.find((assignment) => assignment.variableName === environment.app.progressId)?.valueName
        );

        // The wizard supports currently just one flat part without groups, so we need to flat them
        if (this.displayType === 'wizard') {
            const flatPart = this.flatParts(parts);

            return { id, display, original, parts: flatPart, progress };
        } else {
            return { id, display, original, parts, progress };
        }
    }

    /**
     * Prepare part for display type wizard
     *
     */
    private flatParts(parts: QuestPart[]) {
        let questions: QuestQuestion[] = [];
        parts.forEach((part) => {
            // First get all questions from a part, which are organized in groups
            if (part.groups && !part.questions) {
                // Then search for all questions, which are either flat in a group or deep nested in a subgroup
                part.groups.forEach((group) => {
                    if (group.subGroups && group.questions) {
                        questions = questions.concat(group.questions);
                        // Map all questions recursively, which are nested in subgroups
                        const subQuests = group.subGroups.map((sub) => this.flatSubGroups(sub).questions);
                        subQuests.forEach((sub) => (questions = questions.concat(sub)));
                    } else if (!group.subGroups && group.questions) {
                        questions.concat(group.questions);
                    }
                });
            } else if (!part.groups && part.questions) {
                // Otherwise fetch all questions, which are flat in a part
                questions = questions.concat(part.questions);
            }
        });

        return [{ id: 'MergedFlatPart', questions }];
    }

    /**
     * Search recursive for subgroups
     *
     */
    private flatSubGroups(subgroup: QuestQuestionGroup): QuestQuestionGroup {
        if (subgroup.subGroups) {
            subgroup.subGroups.forEach((sub) => {
                this.flatSubGroups(sub);
            });
        }
        return subgroup;
    }

    /**
     * Prepare data for submit.
     *
     */
    prepare(model: Quest): any {
        return model;
    }

    /**
     * Sets quest type.
     *
     */
    transformType(): QuestDisplayType {
        return this.displayType;
    }

    /**
     * Transform single part - combining original ui group within its variable and state info into quest part
     *
     */
    protected transformPart(
        part: ConfigitItem,
        uigroups: ConfigitItemsObj,
        variables: ConfigitItemsObj,
        states: ConfigitItemsObj,
        values: ConfigitAssignmentsObj
    ): QuestPart {
        const id = <string>part.fullyQualifiedName;
        const uigroup = uigroups[id];
        const state = states[id];
        const title = uigroup.text;
        const icon = state.image && this.transformImageUrl(state.image, 100);
        const headline = uigroup.text;
        const description = this.transformImagePaths(<string>state.info);
        const shown = (uigroup.variables || []).filter((name: string) => states[name].show);
        // create groups if exists
        const subgroups = shown.map((name: string) => uigroups[name]).filter(Boolean);
        const groups = subgroups.length
            ? subgroups.map((group: ConfigitItem) => this.transformSubgroup(group, uigroups, variables, states, values))
            : undefined;
        // questions directly otherwise
        const questions = !groups
            ? shown.map((name: string) => this.transformQuestion(name, variables[name], states[name], values[name]))
            : undefined;
        // valid if none of groups neither questions (only those which have value or optional) reports error
        const errorprone = (questions || []).filter(
            (question: QuestQuestion) => question.value || (question.validate && question.validate.required)
        );
        const items: Array<QuestQuestionGroup | QuestQuestion> = groups || errorprone;
        let valid = (items.length && !items.some((item) => item.error !== false)) || undefined;
        const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);
        const allNestedGroups: QuestQuestionGroup[] = groups ? flatten(groups.map((g) => this.recurseGroups(g))) : [];
        const allNestedQuestions: QuestQuestion[] =
            [...(questions || []), ...flatten(allNestedGroups.map((g) => g.questions || []))] || [];

        const required = (question: QuestQuestion) => question.validate && question.validate.required;

        allNestedQuestions?.forEach((question) => {
            if (question.error || (!question.value && required(question))) {
                valid = undefined;
            }
        });
        // control disabled
        const properties = (name: string) => variables[name] && variables[name].customProperties;
        const disability = (item?: ConfigitItemCustomProperties) => item && item.DisplayAs === 'Disabled' && item;
        const control = (uigroup.variables || []).map(properties).map(disability).filter(Boolean).pop();
        const disabled = control ? control.CalculatedValue === 'True' : undefined;

        return { id, title, icon, headline, description, valid, questions, groups, disabled };
    }

    private recurseGroups(group: QuestQuestionGroup): QuestQuestionGroup[] {
        const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);
        const subs = (group.subGroups || []).map((g) => this.recurseGroups(g));
        return [group, ...flatten(subs)];
    }

    /**
     * Transforms image paths in text
     *
     */
    protected transformImagePaths(text: string): string {
        const pattern = /src="(.+?\/images.+?)"/g;
        const links = [];
        let found;

        while ((found = pattern.exec(text))) {
            links.push(found[1]);
        }

        links.forEach((link) => {
            text = text.replace(link, this.transformImageUrl(link));
        });

        return text;
    }

    /**
     * Transforms subgroup - transforming questions inside
     *
     */
    protected transformSubgroup(
        item: ConfigitItem,
        uigroups: ConfigitItemsObj,
        variables: ConfigitItemsObj,
        states: ConfigitItemsObj,
        values: ConfigitAssignmentsObj
    ): QuestQuestionGroup {
        const id = <string>item.fullyQualifiedName;
        const headline = (item.text !== this.anonymousIndicator && item.text) || '';
        const description = states[id].info;
        const shown = (item.variables || []).filter((name: string) => states[name].show);

        // create subgroups if exists
        const childGroups = shown.map((name: string) => uigroups[name]).filter(Boolean);
        const subGroups = childGroups.map((group: ConfigitItem) =>
            this.transformSubgroup(group, uigroups, variables, states, values)
        );

        const questions = shown
            .filter((name: string) => !uigroups[name])
            .map((name: string) => this.transformQuestion(name, variables[name], states[name], values[name]));
        // set group invalid if any question (required) is invalid or not checked
        const errorprone = questions.filter(
            (question) => question.value || (question.validate && question.validate.required)
        );
        const error = errorprone.some((question) => question.error !== false) || false;

        return { id, headline, description, subGroups, questions, error };
    }

    /**
     * Transforms question - combining original item and state into quest question
     *
     */
    protected transformQuestion(
        id: string,
        item: ConfigitItem,
        state: ConfigitItem,
        set: ConfigitAssignment
    ): QuestQuestion {
        const unit = item.customProperties?.Unit;
        const text = item.text;
        const type = this.questionType(item);
        const description = this.questionDescription(type, item);
        const hint = this.questionHint(type, item, state);
        const options = this.transformOptions(state, item.values);
        const value = this.transformValue(type, set, state);
        const validate = this.transformValidation(type, item, state);
        const error =
            (!state.valid && state.invalidMessage) || (value ? !this.checkValidity(validate, value) : undefined);
        const appearance = this.questionAppearance(item);
        const template = type === QuestionType.file ? this.transformQuestionFileTemplate(item) : undefined;
        const upload = type === QuestionType.file ? this.transformQuestionFileUpload(item) : undefined;
        const date = type === QuestionType.date ? this.transformQuestionDate(item) : undefined;
        const disabled = state.readOnly;
        const placeholder = disabled ? '' : this.questionPlaceholder(type, validate); // empty for disabled
        // clear invalid value as already fully processed
        state.invalidValue = undefined;

        return {
            id,
            type,
            text,
            unit,
            hint,
            description,
            placeholder,
            value,
            options,
            error,
            validate,
            appearance,
            template,
            upload,
            date,
            disabled,
        };
    }

    protected transformQuestionFileTemplate(item: ConfigitItem): QuestFile {
        const customProps = <ConfigitItemCustomProperties>item.customProperties;
        const local = !/^http/.test(<string>customProps.FileUrl);

        return {
            type: customProps.FileType,
            size: customProps.FileSize,
            url: (local ? this.downloadUrl : '') + customProps.FileUrl,
        };
    }

    protected transformQuestionFileUpload(item: ConfigitItem): QuestFileUploadOptions | undefined {
        const customProps = <ConfigitItemCustomProperties>item.customProperties;
        if (customProps.DisplayAs === 'FileUpload') {
            return {
                allow: this.fileTypes,
            };
        }
        return;
    }

    protected transformQuestionDate(item: ConfigitItem): QuestDateOptions | undefined {
        return { withoutDay: item.customProperties.WithoutDay };
    }

    /**
     * Transforms set value - from assignment
     *
     */
    protected transformValue(type: QuestionType, assignment: ConfigitAssignment, state: ConfigitItem): any {
        switch (type) {
            case QuestionType.checkbox: {
                // if required must be undefined - like "untouched" (for mat-error)
                return (assignment && assignment.valueName === 'True') || (state.required ? undefined : false);
            }
            case QuestionType.file: {
                // re-storing file meta info from json for file
                return (assignment && assignment.valueName && JSON.parse(assignment.valueName)) || '';
            }
            case QuestionType.checklist: {
                if (assignment) {
                    const assignments: ConfigitAssignment[] = Array.isArray(assignment) ? assignment : [assignment];
                    return assignments.map((a) => a.valueName);
                }
                return undefined;
            }
            default: {
                // value from assignment or set in state (for assignment error)
                return state.invalidValue !== undefined ? state.invalidValue : assignment && assignment.valueName;
            }
        }
    }

    /**
     * Transforms options for answer question
     *
     */
    protected transformOptions(item: ConfigitItem, values: ConfigitItemValue[]): QuestAnswerOption[] {
        // visible options filter function
        const visible = (option: ConfigitValue) => {
            if (option.state === ConfigitValueState.Blocked || option.state === ConfigitValueState.Removed) {
                return false;
            }
            if (item.displayForceablesAs === ConfigitForceableDisplay.Hide) {
                return !this.isConflict(option);
            }
            return true;
        };
        // order forcable function
        const forceable = (option: ConfigitValue, prev: ConfigitValue) => {
            if (item.displayForceablesAs === ConfigitForceableDisplay.Last) {
                if (option.state === ConfigitValueState.Forceable) {
                    return prev.state !== ConfigitValueState.Forceable ? 1 : 0;
                } else {
                    return prev.state === ConfigitValueState.Forceable ? -1 : 0;
                }
            }
            return 0;
        };

        return (<ConfigitValue[]>item.valueStates)
            .filter(visible)
            .sort(forceable)
            .map((option: ConfigitValue) => ({
                id: option.name,
                text: option.text,
                image: option.image && this.transformImageUrl(option.image),
                description: option.info,
                disabled: false, // by design not: option.state === ConfigitValueState.Forceable,
                custom: this.transformOptionCustom(option),
                recommended: values.find((v) => v.name === option.name)?.properties?.isRecommended,
            }));
    }

    /**
     * Returns custom value for option's custom property
     *
     */
    protected transformOptionCustom(item: ConfigitValue): string {
        return (this.isConflict(item) && 'conflicting') || '';
    }

    private isConflict(item: ConfigitValue) {
        return (
            item.state === ConfigitValueState.Forceable && item.assignmentAuthor !== ConfigitAssignmentAuthor.Default
        );
    }

    /**
     * Transforms image url
     *
     */
    protected transformImageUrl(url: string, resize: number = 300): string {
        if (resize) {
            url = url.replace(/maxHeight=[0-9]+/, `maxHeight=${resize}`);
        }

        // return url.replace(/^(.+?\/images)\?(.+)/, `${environment.configit.url}/configuration/images?$2`);
        return url.replace(/^(.+?\/images)\?(.+)/i, `${this.imageUrl}?$2`);
    }

    /**
     * Transforms validation settings for question
     *
     */
    protected transformValidation(type: QuestionType, item: ConfigitItem, state: ConfigitItem): QuestQuestionValidate {
        return {
            email: item.customProperties && item.customProperties.DisplayAs === 'Email',
            date: type === QuestionType.date,
            integer: type === QuestionType.integer,
            float: type === QuestionType.float,
            min: item.customProperties && item.customProperties.MinValue,
            max:
                (item.customProperties && item.customProperties.MaxValue) ||
                (type === QuestionType.file && this.fileMaxSize),
            required: state.required,
            dateMax: item.customProperties.DateMax,
            dateMin: item.customProperties.DateMin,
        };
    }

    /**
     * Evaluates question item to assign question type
     *
     */
    protected questionType(item: ConfigitItem): QuestionType {
        // prepare criteria fields
        const controlType = item.controlType;
        const dataType = controlType === 'TextBox' && item.dataType; // data type only for text
        const multi = item.isMultiValued && 'Multi';
        const display = item.customProperties && item.customProperties.DisplayAs;
        const props = [controlType, display, dataType, multi].filter(Boolean);
        // then get from mapping (or default)
        // @ts-ignore
        return this.questionTypes[props.join('-')] || QuestionType.text;
    }

    /**
     * Determines question description
     *
     */
    protected questionDescription(type: QuestionType, item: ConfigitItem): string | undefined {
        switch (type) {
            case QuestionType.card: {
                return item.info;
            }
            case QuestionType.file: {
                return item.info;
            }
            default: {
                return;
            }
        }
    }

    /**
     * Gets question placeholder. Uses translations per type.
     *
     *
     * @translate quest.placeholder.email
     * @translate quest.placeholder.text
     * @translate quest.placeholder.dropdown
     * @translate quest.placeholder.date
     * @translate quest.placeholder.integer
     * @translate quest.placeholder.float
     *
     * @translate quest.placeholder.optional
     */
    protected questionPlaceholder(type: QuestionType, validate: QuestQuestionValidate): string | undefined {
        const optional = (!validate.required && ' ' + this.translated('placeholder.optional')) || '';

        switch (type) {
            case QuestionType.date:
            case QuestionType.float:
            case QuestionType.integer:
            case QuestionType.suggest:
            case QuestionType.dropdown: {
                return this.translated(`placeholder.${type}`) + optional;
            }
            case QuestionType.text: {
                const subtype = validate.email ? 'email' : type;
                return this.translated(`placeholder.${subtype}`) + optional;
            }
            default: {
                return;
            }
        }
    }

    /**
     * Determines question hint
     *
     */
    protected questionHint(
        type: QuestionType,
        item: ConfigitItem,
        state: ConfigitItem
    ): { text: string; important: boolean } | undefined {
        let text = state.info;
        if (!text) {
            return;
        }
        const isImportant = (info: string) =>
            info !== undefined && info.replace(/^\s*<.+?>/, '').substring(0, 1) === '!';
        const important = isImportant(text);

        if (important) {
            text = text.replace(/!/, '');
        }

        if (!text) {
            return;
        }

        return { text, important };
    }

    /**
     * Determines question appearance
     *
     */
    protected questionAppearance(item: ConfigitItem): QuestionAppearance {
        switch (item.customProperties && item.customProperties.AppearanceWidth) {
            case 'HalfStandalone': {
                return QuestionAppearance.halfStandalone;
            }
            case 'Full': {
                return QuestionAppearance.full;
            }
            default: {
                return QuestionAppearance.half;
            }
        }
    }

    /**
     * Checks validity of question through defined validators
     *
     *
     * Uses errors from quest validators:
     * @translate quest.error.required
     * @translate quest.error.email
     * @translate quest.error.integer
     * @translate quest.error.float
     * @translate quest.error.min
     * @translate quest.error.max
     */
    protected checkValidity(validate: QuestQuestionValidate, value: any): boolean {
        // helper methods
        const active: (rule: string) => any = (rule: string) => validate[rule];
        const validator = (rule: string) => QuestValidators[rule](validate[rule]);
        // get validator functions for active rules
        const checks: ValidatorFn[] = Object.keys(validate).filter(active).map(validator);
        // then check those
        const control = new UntypedFormControl(value, checks);

        return control.valid;
    }

    /**
     * Gets translation for quest context
     */
    protected translated(text: string): string {
        return this.translate.instant(`quest.${text}`);
    }

    private objectize(data: any[], prop: string): { [key: string]: any } {
        const result: { [key: string]: any } = {};

        const keys = data.map((d) => d[prop]);
        new Set(keys).forEach((key) => {
            const value = data.filter((d) => d[prop] === key);
            result[key] = value?.length === 1 ? value[0] : value;
        });

        return result;
    }
}
