import { fromEvent as observableFromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Prefixable } from '../prefixable.interface';
import { QuestConfig } from '../quest-config.interface';

/**
 * Dialog component used by quest-hint
 *
 * @translate hint.close
 */
@Component({
    selector: 'vi-quest-hint-dialog',
    templateUrl: './quest-hint-dialog.component.html',
})
export class QuestHintDialogComponent extends Prefixable implements OnInit, OnDestroy {
    private resize: Subscription;
    private innerWidth: number;

    constructor(
        private config: QuestConfig,
        public dialog: MatDialogRef<QuestHintDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(config && config.context);
    }

    ngOnInit() {
        // store initial width to check change on resize event
        this.innerWidth = window.innerWidth;
        this.resize = observableFromEvent(window, 'resize')
            .pipe(debounceTime(25))
            .subscribe(() => this.onResize());
    }

    ngOnDestroy() {
        if (this.resize) {
            this.resize.unsubscribe();
        }
    }

    onResize() {
        // react only on change of width to avoid closing of dialog on soft keyboard opened
        if (this.innerWidth !== window.innerWidth) {
            this.innerWidth = window.innerWidth;
            this.close();
        }
    }

    close() {
        this.dialog.close();
    }
}
