import { Component, OnChanges, Input } from '@angular/core';

/**
 * Component renders mat-icon or image
 *
 * @param icon mat-icon name or url
 */

@Component({
    selector: 'vi-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
    @Input() icon: string;

    image: boolean;

    constructor() {}

    ngOnChanges(changes) {
        this.image = this.icon && /(http(s)?:\/\/|www\.)\S+/i.test(this.icon);
    }
}
