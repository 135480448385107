import { Component } from '@angular/core';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';
import { QuestionAppearance } from '../../../../../quest/src/lib/question-appearance.enum';
import { BehaviorSubject } from 'rxjs';
import { QuestValueChange } from '../../../../../quest/src/lib/quest-value-change.interface';
import { QuestPartChange } from '../../../../../quest/src/lib/quest-part-change.interface';

const cardModel: Quest = {
    id: 'TestCardModel',
    display: QuestDisplayType.single,
    // Not neccessary for the QuestLib
    original: undefined,
    parts: [
        {
            id: 'PartDropdown',
            title: 'Dropdown',
            questions: [
                {
                    id: 'TestQuestions01',
                    type: QuestionType.dropdown,
                    text: 'To be or not to be?',
                    value: 'TestValue01',
                    options: [
                        {
                            id: 'TestQuestionOption01',
                            text: 'Option01',
                        },
                        {
                            id: 'TestQuestionOption02',
                            text: '<h1>Option2</h1>',
                            description: 'Test Description',
                        },
                        {
                            id: 'TestQuestionOption03',
                            text: 'Option01',
                            description: 'Test Description',
                            // Can be used for adding custom css classes
                            custom: 'testClass'
                        }
                    ],
                    error: false,
                    appearance: QuestionAppearance.full,
                },
            ]
        }
    ]
};

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

    public panelOpenState = false;

    public model = cardModel;

    public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);

    constructor() {
    }
    check(changed: QuestValueChange) {
        console.log('SomethingChanged');
        console.log(changed);
    }

    page(changed: QuestPartChange) {
        // TODO: do something here
        console.log('next/back was pressed!');
    }

    submit(model: any) {
        // TODO: decide what to do here
        console.log('SUBMIT!');
    }

    private onError(translationKey: string) {
        // TODO: decide what to do with errors
        console.log('error');
    }


}


