import { Component, Input } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

@Component({
    selector: 'vi-quest-answer-number',
    templateUrl: './quest-answer-number.component.html',
    styleUrls: ['./quest-answer-number.component.scss'],
})
export class QuestAnswerNumberComponent extends QuestAnswerComponent {
    @Input() type: string;
}
