<mat-form-field floatLabel="never">
    <mat-select
        [formControl]="control"
        [placeholder]="placeholder"
        (selectionChange)="changed($event)"
        (openedChange)="focused($event)"
    >
        <mat-option *ngIf="config.behavior.allowAnswerReset">
            {{ config.behavior.answerResetLabel }}
        </mat-option>
        <ng-container *ngFor="let option of model; trackBy: optionId">
            <mat-option
                *ngIf="!config.behavior.hideDisabledOptions || !option.disabled"
                [ngClass]="option.custom"
                [value]="option.id"
                [disabled]="option.disabled"
            >
                <span [innerHTML]="option.text | trustHtml"></span>
                <span *ngIf="option.description"> ({{ option.description }})</span>
            </mat-option>
        </ng-container>
    </mat-select>
    <mat-error [viQuestValidationError]="control"></mat-error>
</mat-form-field>
