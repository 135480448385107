import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';

import { QuestPartsComponent } from './quest-parts.component';

@Component({
    selector: 'vi-quest-parts-tabbed',
    templateUrl: './quest-parts-tabbed.component.html',
    styleUrls: ['./quest-parts-tabbed.component.scss'],
})
export class QuestPartsTabbedComponent extends QuestPartsComponent implements OnInit {
    @ViewChild('tabs', { static: true }) tabs: MatTabGroup;

    ngOnInit() {
        super.ngOnInit();
        // workaround for having no blocking tab switch
        this.tabs._handleClick = this.interceptTabChange.bind(this);
    }

    interceptTabChange(tab: MatTab, tabHeader: MatTabHeader, index: number) {
        if (
            !this.config.behavior.restrictPartChange ||
            Array.from(Array(index).keys()).every((i) => this.completed(i))
        ) {
            this.partChange.emit({ index, source: 'header' });
        }
    }
}
