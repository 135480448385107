import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Quest } from '../../../../../quest/src/lib/quest.interface';
import { QuestValueChange } from '../../../../../quest/src/lib/quest-value-change.interface';
import { QuestPartChange } from '../../../../../quest/src/lib/quest-part-change.interface';
import { QuestDisplayType } from '../../../../../quest/src/lib/quest-display-type.enum';
import { QuestionType } from '../../../../../quest/src/lib/question-type.enum';
import { QuestionAppearance } from '../../../../../quest/src/lib/question-appearance.enum';

const datepickerModel: Quest = {
      id: 'TestDatepickerModel',
      display: QuestDisplayType.single,
      // Not neccessary for the QuestLib
      original: undefined,
      parts: [
        {
          id: 'PartDatepicker',
          title: 'Datepicker',
          questions: [
            {
              id: 'TestQuestions01',
              type: QuestionType.date,
              text: 'To be or not to be?',
              value: 'Test',
              error: false,
              appearance: QuestionAppearance.half,
              placeholder: 'Testplaceholder'
            },
          ],
          description: 'Test Description'
        }
      ]
    };

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {

  public model = datepickerModel;

  public quest: BehaviorSubject<Quest> = new BehaviorSubject<Quest>(this.model);

  constructor() {
  }
  check(changed: QuestValueChange) {
    console.log('SomethingChanged');
  }

  page(changed: QuestPartChange) {
    // TODO: do something here
    console.log('next/back was pressed!');
  }

  submit(model: any) {
    // TODO: decide what to do here
    console.log('SUBMIT!');
  }

  private onError(translationKey: string) {
    // TODO: decide what to do with errors
    console.log('error');
  }

}
