import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {

    constructor() {
    }

    private emitEvent = new Subject<any>();

    changeEmitted = this.emitEvent.asObservable();

    emitParamChange(params: {lang?: string, model?: string, displayMode?: string}) {
        this.emitEvent.next(params);
    }
}
