import { Moment } from 'moment';
import moment from 'moment';

export class DateUtils {
    static parseDateMax(value: string): Moment {
        const maxDate = moment().endOf('day');
        return this.parseMinMax(maxDate, value);
    }

    static parseDateMin(value: string): Moment {
        const minDate = moment().startOf('day');
        return this.parseMinMax(minDate, value);
    }

    static parseMinMax(base: Moment, value: string): Moment {
        // parse string like e.g. "50y13m2d" into ["50y", "13m", "2d"] to get years, months and days
        const duration = value.split(/(\d+[y|m|d])/g).filter(Boolean);
        duration.forEach((d) => {
            const amount = parseInt(d.substr(0, d.length - 1), 10);
            const unit = d.substr(d.length - 1) as moment.unitOfTime.DurationConstructor;
            base = base.add(-amount, unit);
        });
        return base;
    }
}
