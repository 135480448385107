import { Component, Input } from '@angular/core';

import { QuestAnswerComponent } from './quest-answer.component';

/**
 * Component for showing prefilled question's value as readonly textblock
 *
 * @param headline The questions title
 * @param description The description of current question, can contain html
 * @param text The prefilled answer's value as readonly textblock, can contain html
 * @param error The error flag used by validation icon
 *
 */
@Component({
    selector: 'vi-quest-answer-hint',
    templateUrl: './quest-answer-hint.component.html',
    styleUrls: ['./quest-answer-hint.component.scss'],
})
export class QuestAnswerHintComponent extends QuestAnswerComponent {
    @Input() headline: string;
    @Input() description: string;
    @Input() text: string;
    @Input() error: boolean;
    @Input() unit: string;
    @Input() hint: { text: string; important: boolean };
    @Input() isFocused: boolean;
}
