<form #form>
    <div *ngFor="let part of model; last as last; let i = index; trackBy: id" [ngClass]="{ disabled: part.disabled }">
        <ng-container *ngIf="part.groups?.length || part.questions?.length">
            <h2>{{ part.headline }}</h2>
            <p [innerHTML]="part.description | trustHtml"></p>
        </ng-container>
        <div fxLayout="row wrap">
            <ng-container *ngIf="part.groups && !part.questions">
                <vi-quest-group
                    *ngFor="let group of part.groups; trackBy: id"
                    fxFlex="100"
                    [model]="group"
                    (check)="changed($event, i)"
                    [hideIfEmpty]="config.behavior.hideEmptyGroups"
                    [disabled]="checking"
                >
                </vi-quest-group>
            </ng-container>
            <ng-container *ngIf="part.questions && !part.groups">
                <ng-container *ngFor="let question of part.questions; trackBy: id">
                    <vi-quest-question
                        attr.quest-selector="{{ question.id | lowercase }}"
                        [attr.class]="question.appearance"
                        fxFlex="100"
                        [fxFlex.gt-sm]="question.appearance === 'full' ? 100 : 50"
                        [model]="question"
                        [error]="question.error"
                        (check)="changed($event, i)"
                        [checking]="checking"
                    >
                    </vi-quest-question>
                    <vi-quest-question
                        attr.quest-selector="{{ question.id | lowercase }}"
                        *ngIf="question.appearance === 'half-standalone' || question.appearance === 'full'"
                        [model]="{ type: 'empty', appearance: question.appearance }"
                        class="empty"
                        [fxHide]="question.appearance === 'full'"
                    >
                    </vi-quest-question>
                </ng-container>
            </ng-container>
        </div>
        <ng-container
            *ngIf="part.submit || (last && part.submit !== false)"
            [ngTemplateOutlet]="submitButton"
            [ngTemplateOutletContext]="{ part: part, index: i }"
        >
        </ng-container>
    </div>
</form>
<ng-template #submitButton let-part="part" let-index="index">
    <re-captcha
        *ngIf="config?.behavior?.useCaptcha && (!config?.captcha?.version || config?.captcha?.version === 'v2')"
        [site_key]="config.captcha?.siteKey"
        [language]="lang"
        [size]="config.captcha?.size"
        [theme]="config.captcha?.theme"
        [type]="config.captcha?.type"
        (captchaResponse)="handleCaptcha($event)"
        (captchaExpired)="expired()"
    >
    </re-captcha>
    <button mat-raised-button color="primary" type="button" (click)="submit(false, index)" [disabled]="checking">
        <ng-container *ngIf="!part.submit?.key">{{
            prefixed('button.' + ((part.submit === true && 'submit') || part.submit || 'submit')) | translate
        }}</ng-container>
        <ng-container *ngIf="part.submit?.key">{{
            prefixed('button.' + part.submit.key) | translate: part.submit.params
        }}</ng-container>
    </button>
</ng-template>
