import { Component } from '@angular/core';

@Component({
  selector: 'app-configit',
  templateUrl: './configit.component.html',
  styleUrls: ['../installation.component.scss']
})
export class ConfigitComponent {

  public text = '\n@Component({\n' +
      '    selector: \'app-root\',\n' +
      '    templateUrl: \'./app.component.html\',\n' +
      '    styleUrls: [\'./app.component.scss\']\n' +
      '})\n' +
      'export class AppComponent implements OnInit {\n' +
      '\n' +
      '    public quest: Subject<Quest> = new Subject<Quest>();\n' +
      '\n' +
      '    constructor(private questService: ConfigitQuestAdapterService,\n' +
      '                private translate: TranslateService) {\n' +
      '    }\n' +
      '\n' +
      '    ngOnInit(): void {\n' +
      '        this.translate.setDefaultLang(\'de-DE\');\n' +
      '        this.questService.get(\'configitProjectId\').subscribe((model: Quest) => this.setModel(model));\n' +
      '    }\n' +
      '\n' +
      '    setModel(model: Quest) {\n' +
      '       this.model.next(model)\n' +
      '     }\n' +
      '\n' +
      '    check(changed: QuestValueChange) {\n' +
      '        // This event fires after each answer to a question.\n' +
      '        console.log(\'SomethingChanged\');\n' +
      '    }\n' +
      '\n' +
      '    page(changed: QuestPartChange) {\n' +
      '        // This event fires after each part change for- and backwards in tabbed or stepped mode.\n' +
      '        console.log(\'next/back was pressed!\');\n' +
      '    }\n' +
      '\n' +
      '    submit(model: any) {\n' +
      '        // This event fires if all questions are answered and the next/submit button is pressed.\n' +
      '        console.log(\'SUBMIT!\');\n' +
      '    }\n' +
      '}';

  public template = '\n<vi-quest\n' +
      '   [model]="quest"\n' +
      '   (check)="check($event)"\n' +
      '   (partChange)="page($event)"\n' +
      '   (submit)="submit($event)">\n' +
      '</vi-quest>';

  constructor() { }

}
