<mat-radio-group
    fxLayout="column"
    fxLayoutGap="10px"
    [name]="id"
    [formControl]="control"
    (change)="changed($event)"
    (focusin)="focused(true)"
    (focusout)="focused(false)"
>
    <ng-container *ngFor="let option of model; trackBy: optionId">
        <mat-radio-button
            fxFlex
            *ngIf="!config.behavior.hideDisabledOptions || !option.disabled"
            [ngClass]="option.custom"
            [value]="option.id"
            [disabled]="option.disabled"
            (click)="select(option.description)"
        >
            <span [innerHTML]="option.text | trustHtml"></span>
        </mat-radio-button>
    </ng-container>
</mat-radio-group>
<div></div>

<div class="mat-radio-description-wrapper" [hidden]="!description" *ngIf="config?.behavior?.alwaysShowPrevNext">
    <div class="mat-radio-description" [innerHTML]="description | trustHtml"></div>
</div>

<div class="mat-form-field-subscript-wrapper">
    <mat-error [viQuestValidationError]="control" [hidden]="!control.touched || disabled"></mat-error>
</div>
