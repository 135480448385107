<mat-form-field *ngIf="control" floatLabel="never" (click)="picker.open()">
    <input
        matInput
        [name]="id"
        [max]="maxDate"
        [min]="minDate"
        [matDatepicker]="picker"
        [formControl]="control"
        [placeholder]="placeholder"
        (change)="changed($event)"
        (dateChange)="updated($event)"
        (focus)="focused(true, $event)"
        (click)="focused(true, $event)"
        (blur)="focused(false, $event)"
        #dpInput
    />
    <mat-error [viQuestValidationError]="control"></mat-error>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <button
        *ngIf="config.behavior.enterButton && control.value && !control.disabled && question.focused"
        mat-icon-button
        matSuffix
        class="enter"
    >
        <mat-icon>subdirectory_arrow_left</mat-icon>
    </button>
    <button
        *ngIf="config.behavior.allowAnswerReset && control.value && !control.disabled"
        tabindex="-1"
        matSuffix
        mat-icon-button
        (click)="clearValue($event)"
    >
        <mat-icon>close</mat-icon>
    </button>
    <mat-datepicker
        (opened)="focused(true)"
        (closed)="focused(false)"
        [startView]="withoutDay ? 'multi-year' : 'month'"
        (monthSelected)="monthSelected($event, picker, dpInput)"
        #picker
    ></mat-datepicker>
</mat-form-field>
