<mat-checkbox [name]="id" [formControl]="control" (change)="changed($event)">
    <span [innerHTML]="model.text | trustHtml"></span>
    <vi-quest-hint *ngIf="hint" [model]="hint"></vi-quest-hint>
</mat-checkbox>
<div class="mat-form-field-subscript-wrapper">
    <mat-error
        [viQuestValidationError]="control"
        [style.visibility]="control.touched ? 'visible' : 'hidden'"
        [hidden]="!control.touched"
    ></mat-error>
</div>
