/**
 * Question render type within flex settings:
 * - half - default 50%
 * - full - 100%
 * - half standalone - 50% with preserved empty space after
 */
export enum QuestionAppearance {
    half = 'half',
    full = 'full',
    halfStandalone = 'half-standalone',
}
