import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    public lang: string;
    private _salesAreaName: string | null;
    private _plant: string | null;
    get salesAreaName(): string | null {
        if (this._salesAreaName) {
            return this._salesAreaName;
        }

        switch (this.lang) {
            case 'de-DE':
                return 'Germany';
            case 'fr-FR':
                return 'France';
            case 'fr-BE':
            case 'nl-BE':
                return 'Belgium';
            case 'en-US':
                return 'USA';
            case 'en-CA':
                return 'Canada';
            case 'it-IT':
            case 'en-IT':
                return 'Italy';
            default:
                return 'Germany';
        }
    }
    set salesAreaName(value: string | null) {
        this._salesAreaName = value;
    }

    get plant(): string | null {
        if (this._plant) {
            return this._plant;
        }

        switch (this.lang) {
            case 'de-DE':
            case 'en-DE':
                return '0500';
            case 'fr-FR':
                return '0600';
            case 'fr-BE':
            case 'nl-BE':
                return '6000';
            case 'en-US':
                return '8100';
            case 'en-CA':
                return '9900';
            case 'it-IT':
            case 'en-IT':
                return '7300';
            default:
                return '0500';
        }
    }

    set plant(value: string | null) {
        this.plant = value;
    }

    private _configLanguage: string | null;
    get configLanguage(): string | null {
        return this._configLanguage || this.lang?.replace('-', '_');
    }
    set configLanguage(value: string | null) {
        this._configLanguage = value;
    }
}
