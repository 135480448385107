<mat-selection-list [formControl]="control" (selectionChange)="changed($event)">
    <mat-list-option
        *ngFor="let option of model; trackBy: optionId"
        [value]="option.id"
        [ngClass]="option.custom"
        checkboxPosition="before"
    >
        <span [innerHTML]="option.text | trustHtml"></span>
    </mat-list-option>
</mat-selection-list>
<div class="mat-form-field-subscript-wrapper">
    <mat-error
        [viQuestValidationError]="control"
        [style.visibility]="control.touched ? 'visible' : 'hidden'"
        [hidden]="!control.touched"
    ></mat-error>
</div>
